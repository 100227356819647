import React from "react";
import { Card, Dropdown, Form, OverlayTrigger } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import { IPagination, UtcTimePopover } from "../screenshots/screenshots.component";
import DatePicker from "react-datepicker";
import { addDays } from "../../utilities/date.utilities";
import { getMotherTicketClient } from "../../services/api-clients.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { MotherTicketForListResponseModel, OrganizationType } from "../../swagger-clients/s365-admin-panel-clients.service";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import { TargetProcessLink } from "../../components/target-process-link/target-process-link.component";
import Select from "react-select";
import { ReactSelectOption } from "../users/user-activity-level-select/user-activity-level-select.component";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import BootstrapTooltip from "../../components/bootstrap-tooltip/bootstrap-tooltip";

type TpOrganizationsListProps = {

}

const getTodayDate = () => {
    let dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0);
    return dateNow;
}

const getDefaultStartDate = () => {

    return addDays(getTodayDate(), -30);
}


const getDefaultEndDate = () => {
    let tomorrow = addDays(new Date(), 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow;

}
type SortingOrder = {
    column: string;
    isDescending: boolean;
}

const organizationTypeOptions: ReactSelectOption[] = [
    {
        label: "Company", value: OrganizationType.Company
    },
    {
        label: "University", value: OrganizationType.University
    },
    {
        label: "Consulting", value: OrganizationType.Consulting
    }
];

export const TpOrganizationsList: React.FC<TpOrganizationsListProps> = (props) => {
    const [organizations, setOrganizations] = React.useState<MotherTicketForListResponseModel[]>([]);
    const [pagination, setPagination] = React.useState<IPagination>({
        currentPage: 1,
        recordsPerPage: 20,
        totalRecords: 0
    });
    const [fromDate, setFromDate] = React.useState<Date>();
    const [toDate, setToDate] = React.useState<Date>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>();
    const [organizationType, setOrganizationType] = React.useState<OrganizationType>();
    const [orderBy, setOrderBy] = React.useState<SortingOrder>({ column: "createdAt", isDescending: true });

    const [showHiddenOrganizations, setShowHiddenOrganizations] = React.useState<boolean>(false);
    React.useEffect(() => { getTpOrganizations(); }, [pagination.recordsPerPage, pagination.currentPage, orderBy.column, orderBy.isDescending]);

    const getTpOrganizations = async () => {
        try {
            setOrganizations([]);
            setIsLoading(true);

            const { recordsPerPage, currentPage } = pagination;
            const client = getMotherTicketClient();
            const startDateUtc = fromDate ? new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes(), fromDate.getSeconds())) : null;
            const endDateUtc = toDate ? new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), toDate.getSeconds())) : undefined;
            const organizationsResponse = await client.getMotherTickets(searchTerm, organizationType, showHiddenOrganizations, startDateUtc, endDateUtc,
                orderBy.column, orderBy.isDescending, (currentPage - 1) * recordsPerPage, recordsPerPage);
            setOrganizations(organizationsResponse?.items ?? []);
            setPagination(s => ({ ...s, totalRecords: organizationsResponse?.totalRecords ?? 0 }));


        } catch (error) {
            ShowErrorFromResponse(error, "An error ocurred while getting TP organizations.");
        } finally {
            setIsLoading(false);
        }
    }

    const onTodayClick = () => {
        setFromDate(getTodayDate());
        setToDate(getDefaultEndDate());

    }
    const onLast7DaysClick = () => {
        const startDate = addDays(getTodayDate(), -7);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }
    const onLast30DaysClick = () => {
        const startDate = addDays(getTodayDate(), -30);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }

    const onLastMonthClick = () => {
        const dateNow = new Date();
        const firstInMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        let firstInNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        firstInNextMonth.setMonth(firstInMonth.getMonth() + 1);
        const lastDayOfMonth = addDays(firstInNextMonth, -1);
        setFromDate(firstInMonth);
        setToDate(lastDayOfMonth);

    }

    const onLastYearClick = () => {
        const dateNow = new Date();
        const januaryFirst = new Date(dateNow.getFullYear(), 0, 1, 0, 0, 0);
        setFromDate(januaryFirst);
        setToDate(getDefaultEndDate());

    }

    const onAddDayClick = (numberOfDays: number) => {

        const newDate = addDays(fromDate ?? new Date(), numberOfDays);
        setFromDate(newDate);
    }
    const onAddMonthClick = (numberOfMonths: number) => {
        let newDate = !!fromDate ? new Date(fromDate) : new Date();
        newDate.setMonth(newDate.getMonth() + numberOfMonths);
        setFromDate(newDate);
    }
    const onHideOrganizationClick = async (motherTicketId: number) => {
        try {
            const client = getMotherTicketClient();
            await client.hideMotherTicket(motherTicketId);
            toast.success("Organization is successfully hidden.");
            getTpOrganizations();

        } catch (error) {
            ShowErrorFromResponse(error, "An error ocurred while hiding TP organization.");
        }
    }
    const onRemoveHideFromOrganizationClick = async (motherTicketId: number) => {
        try {
            const client = getMotherTicketClient();
            await client.removeHideFromMotherTicket(motherTicketId);
            toast.success("Organization is successfully restored.");
            getTpOrganizations();

        } catch (error) {
            ShowErrorFromResponse(error, "An error ocurred while restoring TP organization.");
        }
    }

    const onColumnClick = (column: string) => {

        if (orderBy.column == column) {
            setOrderBy({ column: column, isDescending: !orderBy.isDescending });
        } else {
            setOrderBy({ column: column, isDescending: false });

        }
    }


    const getSortValue = (columnName: string) => {
        if (orderBy.column == columnName) {
            if (orderBy.isDescending) {
                return "desc";
            } else {
                return "asc";
            }
        }
        return undefined;
    }

    const { totalRecords, currentPage, recordsPerPage } = pagination;
    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    return <div> <SubHeader key="sub-header" title="TP Organizations" leftItems={[]} rightItems={[]} />
        <ContentWrapper key="content-wrapper" className="pl-4 pr-4">
            <Card className='mb-4' style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                <div className="pt-3 pl-3 pr-3 mr-3 d-flex justify-content-between">
                    <form className="w-100" onSubmit={(ev) => { ev.preventDefault(); if (currentPage !== 1) { setPagination(s => ({ ...s, currentPage: 1 })); } else { getTpOrganizations(); } }} action="">
                        <div className="form-row align-items-center">
                            <div className="col-4 input-group mr-2 mb-2" style={{ minWidth: "250px" }}>
                                <span className="input-group-text"><i className="far fa-user"></i></span>
                                <input type="text"
                                    name="searchTerm"
                                    className="form-control"
                                    onChange={(ev) => { const value = ev.target.value.toString(); setSearchTerm(value); }}
                                    placeholder="Name" value={searchTerm}
                                />
                            </div>
                            <div className="col-2 mr-2 mb-2" style={{ minWidth: "200px" }}>
                                <Select
                                    placeholder={"Organization type"}
                                    isMulti={false}
                                    isClearable
                                    options={organizationTypeOptions}
                                    onChange={(newValue) => {
                                        const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? +newValue.value : undefined;
                                        setOrganizationType(value);
                                    }}
                                />
                            </div>

                            <div className="input-group mr-2 mb-3" style={{ width: '380px' }}>
                                <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
                                <div className="input-group__datepicker-wrapper">
                                    <DatePicker
                                        id="fromDate"
                                        className="form-control"
                                        selected={fromDate}
                                        onChange={(date: Date) => {
                                            if (!date) {
                                                setFromDate(undefined);
                                            } else
                                                setFromDate(new Date(Date.parse(date != null ? date.toString() : "")));
                                        }}
                                        showTimeSelect
                                        maxDate={toDate}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm" />
                                </div>
                                <span className="input-group-text">-</span>

                                <div className="input-group__datepicker-wrapper">
                                    <DatePicker
                                        id="toDate"
                                        className="form-control"
                                        selected={toDate}
                                        onChange={(date: Date) => {
                                            if (!date) {
                                                setToDate(undefined);
                                            } else
                                                setToDate(new Date(Date.parse(date != null ? date.toString() : "")))
                                        }}
                                        showTimeSelect
                                        maxDate={new Date()}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm" />
                                </div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" className="datePickerWithDropdown" id="dropdown-basic" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={onTodayClick} >Today</Dropdown.Item>
                                        <Dropdown.Item onClick={onLast7DaysClick}>Last 7 days</Dropdown.Item>
                                        <Dropdown.Item onClick={onLast30DaysClick}>Last 30 days</Dropdown.Item>
                                        <Dropdown.Item onClick={onLastMonthClick}>This month</Dropdown.Item>
                                        <Dropdown.Item onClick={onLastYearClick}>This year</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddDayClick(1) }}>+ day</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddDayClick(-1) }}>- day</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddMonthClick(1) }}>+ month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { onAddMonthClick(-1) }}>- month</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>
                            <div className="col-auto mr-2 mb-3">
                                <Form.Check type="checkbox"
                                    checked={showHiddenOrganizations}
                                    onChange={(ev: any) => { setShowHiddenOrganizations(ev.target.checked); }}
                                    label="Show hidden" />
                            </div>




                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary mb-2" >Search</button></div>
                        </div>
                    </form>


                    <div className="d-flex flex-row" style={{ gap: "10px" }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center", width: "max-content" }}>
                            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{totalRecords}</b>
                            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Total records</span>
                        </div>
                        <select className="form-control form-select" style={{ width: "80px", alignSelf: "center" }} value={recordsPerPage} onChange={(event) => setPagination(s => ({ ...s, recordsPerPage: +event.target.value }))}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>

                </div>
            </Card>
            <Card >
                <Card.Body>

                    <div className="">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th
                                        className={"align-middle sortable " + getSortValue("id")}
                                        onClick={() => { onColumnClick("id") }}
                                    >
                                        Id</th>
                                    <th
                                        className={"align-middle sortable " + getSortValue("name")}
                                        onClick={() => { onColumnClick("name") }}
                                    >Name</th>
                                    <th
                                        className={"align-middle text-center sortable " + getSortValue("numberOfUsers")}
                                        onClick={() => { onColumnClick("numberOfUsers") }}
                                    >Users</th>
                                    <th
                                        className={"align-middle text-center sortable " + getSortValue("numberOfRegistrantsForUserMeeting")}
                                        onClick={() => { onColumnClick("numberOfRegistrantsForUserMeeting") }}
                                    >UM registrants</th>
                                    <th
                                        className={"align-middle text-center sortable " + getSortValue("numberOfParticipantsForUserMeeting")}
                                        onClick={() => { onColumnClick("numberOfParticipantsForUserMeeting") }}
                                    >UM participants</th>
                                    <th
                                        className={"align-middle text-center sortable " + getSortValue("numberOfTrialLicenses")}
                                        onClick={() => { onColumnClick("numberOfTrialLicenses") }}
                                    >Trial licenses</th>
                                    <th
                                        className={"align-middle text-center sortable " + getSortValue("numberOfPaidLicenses")}
                                        onClick={() => { onColumnClick("numberOfPaidLicenses") }}
                                    >Paid licenses</th>
                                    <th
                                        className={"align-middle text-center sortable " + getSortValue("createdAt")}
                                        onClick={() => { onColumnClick("createdAt") }}
                                    >Created</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {organizations.map((item) =>
                                (<tr key={item.id}>
                                    <td className="align-middle" style={{ width: "100px" }}>{item.id}</td>
                                    <td className="align-middle">
                                        <TargetProcessLink
                                            targetProcesMotherTicketId={item.id}
                                            ticketName={item.name}
                                            isUniversity={item.isUniversity}
                                            isConsulting={item.isConsulting} />

                                    </td>

                                    <td className="align-middle text-center"><Link to={`/users?motherTicketId=${item.id}`}>{item.numberOfUsers ?? "-"}</Link></td>
                                    <td className="align-middle text-center">{item.numberOfRegistrantsForUserMeeting ?? "-"}</td>
                                    <td className="align-middle text-center">{item.numberOfParticipantsForUserMeeting ?? "-"}</td>
                                    <td className="align-middle text-center">
                                        <BootstrapTooltip tooltipText={<span>
                                            Users with licenses: {item.usersWithTrialLicenses ?? "-"} <br />
                                            Total licenses : {item.numberOfTrialLicenses ?? "-"}
                                        </span>} placement="top">
                                            <span>{item.usersWithTrialLicenses ?? "-"} / {item.numberOfTrialLicenses ?? "-"}</span>
                                        </BootstrapTooltip>
                                    </td>
                                    <td className="align-middle text-center">

                                        <BootstrapTooltip tooltipText={<span>
                                            Users with licenses: {item.usersWithPaidLicenses ?? "-"} <br />
                                            Total licenses : {item.numberOfPaidLicenses ?? "-"}
                                        </span>} placement="top">
                                            <span>{item.usersWithPaidLicenses ?? "-"} / {item.numberOfPaidLicenses ?? "-"}</span>
                                        </BootstrapTooltip>
                                    </td>
                                    <td className="align-middle text-center">
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                            <span><DateTimeDisplayUTC date={item.cratedAt!} /></span>
                                        </OverlayTrigger>
                                    </td>
                                    <td>
                                        {!item.isHidden && <button type="button"
                                            className="btn btn-sm btn-secondary ml-2"
                                            onClick={() => { onHideOrganizationClick(item.id); }}>Hide</button>}
                                        {item.isHidden && <button type="button"
                                            className="btn btn-sm btn-secondary ml-2"
                                            onClick={() => { onRemoveHideFromOrganizationClick(item.id); }}>Show</button>}
                                    </td>

                                </tr>))
                                }

                                {!!isLoading &&
                                    <tr>
                                        <td colSpan={9} className="text-center">Loading...</td>
                                    </tr>
                                }


                            </tbody>
                        </table>

                        {totalPages > 1 &&
                            <div className="d-flex align-items-center">
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={10}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    forcePage={currentPage - 1}
                                    onPageChange={(page: any) => {
                                        console.log("Selected page:", page);
                                        setPagination(s => ({ ...s, currentPage: page.selected + 1 }));
                                        document.body.scrollTop = 0; // For Safari
                                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                    }}
                                    containerClassName={'pagination mb-0'}
                                    activeClassName={'active'}
                                />

                            </div>
                        }
                    </div>



                </Card.Body>
            </Card>
        </ContentWrapper></div>;
}