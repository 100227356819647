import * as React from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { LogMessageModel } from '../../swagger-clients/s365-admin-panel-clients.service';
import { addDays } from "../../utilities/date.utilities";
import queryString from "query-string";
import { useLocation } from "react-router-dom";


interface IScreenshotsSearchProps {
    filter: ScreenshotsSearchFilter;
    selectedMessages: LogMessageModel[];
    getLogMessages: (user: string, computerName: string, fromDate: Date | undefined, toDate: Date | undefined, currentPage: number, recordsPerPage: number) => void;
    totalRecords: number,
    onFilterChange: (value: ScreenshotsSearchFilter) => void,
    onSearchClick: (value?: ScreenshotsSearchFilter) => void
}

const getDefaultStartDate = () => {
    let dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0);
    return dateNow;
}
const getDefaultEndDate = () => {
    let tomorrow = addDays(new Date(), 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow;

}

export type ScreenshotsSearchFilter = {
    fromDate?: Date;
    toDate?: Date;
    user?: string;
    computerName?: string;
    page?: number;
    recordsPerPage?: number;
}

export const getDefaultScreenshotsFilter = () => {
    return {
        user: "",
        computerName: "",
        fromDate: getDefaultStartDate(),
        toDate: getDefaultEndDate(),
        page: 1,
        recordsPerPage: 50
    } as ScreenshotsSearchFilter;
}

export type ScreenshotsSearchType = {
    loadFiltersFromQueryParams: () => void;
}

const ScreenshotsSearch: React.FC<IScreenshotsSearchProps> = (props) => {  

    const resetForm = () => {
        props.onSearchClick(getDefaultScreenshotsFilter());
    }

    const onTodayClick = () => {
        props.onFilterChange({ ...props.filter, fromDate: getDefaultStartDate(), toDate: getDefaultEndDate(), page: 1 });

    }
    const onLast7DaysClick = () => {
        const startDate = addDays(getDefaultStartDate(), -7);
        props.onFilterChange({ ...props.filter, fromDate: startDate, toDate: getDefaultEndDate(), page: 1 });

    }

    const onLastMonthClick = () => {
        const dateNow = new Date();
        const firstInMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        let firstInNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        firstInNextMonth.setMonth(firstInMonth.getMonth() + 1);
        const lastDayOfMonth = addDays(firstInNextMonth, -1);
        props.onFilterChange({ ...props.filter, fromDate: firstInMonth, toDate: lastDayOfMonth, page: 1 });

    }

    const onLastYearClick = () => {
        const dateNow = new Date();
        const januaryFirst = new Date(dateNow.getFullYear(), 0, 1, 0, 0, 0);
        props.onFilterChange({ ...props.filter, fromDate: januaryFirst, toDate: getDefaultEndDate(), page: 1 });

    }

    const onAddDayClick = (numberOfDays: number) => {
        const newDate = addDays(props.filter.fromDate ?? new Date(), numberOfDays);
        props.onFilterChange({ ...props.filter, fromDate: newDate, page: 1 });

    }
    const onAddMonthClick = (numberOfMonths: number) => {
        let newDate = !!props.filter.fromDate ? new Date(props.filter.fromDate) : new Date();
        newDate.setMonth(newDate.getMonth() + numberOfMonths);
        props.onFilterChange({ ...props.filter, fromDate: newDate, page: 1 });
    }

    const { user, computerName, toDate, fromDate, page, recordsPerPage } = props.filter;
    console.log("user", user);
    return <div className="pt-3 pl-3 pr-3" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

        <div className="input-group mr-2 mb-3" style={{ width: '200px' }}>
            <span className="input-group-text"><i className="far fa-user"></i></span>
            <input type="text" className="form-control" placeholder="Username" value={user}
                onChange={(ev) => {
                    console.log("Username input changed", ev.target.value);
                    props.onFilterChange({ ...props.filter, user: ev.target.value });
                }} />
        </div>

        <div className="input-group mr-2 mb-3" style={{ width: '200px' }}>
            <span className="input-group-text"><i className="fas fa-desktop"></i></span>
            <input type="text" className="form-control" placeholder="Computer name" value={computerName ?? ""} onChange={(ev) =>
                props.onFilterChange({ ...props.filter, computerName: ev.target.value })} />
        </div>

        <div className="input-group mr-2 mb-3" style={{ width: '380px' }}>
            <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
            <div className="input-group__datepicker-wrapper">
                <DatePicker
                    id="fromDate"
                    className="form-control"
                    selected={fromDate}
                    onChange={(date?: Date) => {
                        console.log("From date changed:", date);
                        const newDate = !!date ? new Date(Date.parse(date != null ? date.toString() : "")) : undefined;
                        props.onFilterChange({ ...props.filter, fromDate: newDate })
                    }}
                    showTimeSelect
                    maxDate={toDate}
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm" />
            </div>
            <span className="input-group-text">-</span>

            <div className="input-group__datepicker-wrapper">
                <DatePicker
                    id="toDate"
                    className="form-control"
                    selected={toDate}
                    onChange={(date: Date) => {
                        const newDate = !!date ? new Date(Date.parse(date != null ? date.toString() : "")) : undefined;
                        props.onFilterChange({ ...props.filter, toDate: newDate })
                    }}
                    showTimeSelect
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm" />
            </div>
            <Dropdown>
                <Dropdown.Toggle variant="primary" className="datePickerWithDropdown" id="dropdown-basic" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onTodayClick} >Today</Dropdown.Item>
                    <Dropdown.Item onClick={onLast7DaysClick}>Last 7 days</Dropdown.Item>
                    <Dropdown.Item onClick={onLastMonthClick}>This month</Dropdown.Item>
                    <Dropdown.Item onClick={onLastYearClick}>This year</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddDayClick(1) }}>+ day</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddDayClick(-1) }}>- day</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddMonthClick(1) }}>+ month</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddMonthClick(-1) }}>- month</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>


        </div>

        <div className="mb-3">
            <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => props.getLogMessages(user, computerName, fromDate, toDate, 1, recordsPerPage)}>Search</button>


            <button type="button" className="btn btn-default" style={{ marginRight: '50px' }} onClick={() => resetForm()}> <i className="fas fa-eraser" /> </button>
        </div>
        <div className="mb-3 mr-3" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{props.totalRecords}</b>
            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Total records</span>
        </div>
        <div>
            <select className="form-control form-select" value={recordsPerPage} onChange={(event) =>
                props.onFilterChange({ ...props.filter, recordsPerPage: +event.target.value })}>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2500</option>
            </select>
        </div>
    </div>;
}

export default ScreenshotsSearch;