import React from "react";
import { UserActivityLevel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";

type UserActivityLevelBadgeProps = {
    userActivityLevel?: UserActivityLevel;
    size?: number;
}

export const UserActivityLevelBadge: React.FC<UserActivityLevelBadgeProps> = (props) => {



    return (
        <CircularBadge
            color={getUserActivityLevelColor(props.userActivityLevel)}
            sizePx={`${props?.size ?? 20}px`}
            description={getUserActivityLevelDescription(props.userActivityLevel)} />
    );
    

}

const getUserActivityLevelColor = (activityLevel?: UserActivityLevel) => {
    switch (activityLevel) {
        case UserActivityLevel.Green:
            return "green";
        case UserActivityLevel.Yellow:
            return "yellow";
        case UserActivityLevel.Orange:
            return "orange";
        case UserActivityLevel.Red:
            return "red";
        default:
            return "white";


    }
}
const getUserActivityLevelDescription = (activityLevel?: UserActivityLevel) => {
    switch (activityLevel) {
        case UserActivityLevel.Green:
            return "Logged in 3+ times";
        case UserActivityLevel.Yellow:
            return "Logged in at least once";
        case UserActivityLevel.Orange:
            return "Confirmed registration";
        case UserActivityLevel.Red:
            return "Inactive";
        default:
            return "-";


    }
}

const CircularBadge = ({ color, sizePx, description }: { color: string, sizePx: string, description: string }) => {
    const badgeStyle = {
        backgroundColor: color,
        borderRadius: '50%',
        width: sizePx,
        height: sizePx,
        display: 'inline-block',
    };

    return (
        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover id="popover-positioned-top" >
            <span style={{ padding: "5px" }}> {description}</span>
        </Popover>}>
            <Badge style={badgeStyle}></Badge>
        </OverlayTrigger>
    );
};

