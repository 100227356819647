import React from "react";
import { Card, Dropdown, Form, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import { DwsimProClient, DwsimProUsagePerUserModel, DwsimProLicenseModel, GetDwsimProUsagePostModel, OrganizationType } from "../../swagger-clients/s365-admin-panel-clients.service";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { EmailWithLinkToDomain } from "../../components/email-with-link-to-domain/email-with-link-to-domain.component";
import { IPagination, UtcTimePopover } from "../screenshots/screenshots.component";
import { getDwsimProClient } from "../../services/api-clients.service";
import { _copyAndSortWithSecondaryColumn } from "../../utilities/array.helpers";
import UserDetailsModal from "../users/user-details-modal/user-details-modal.component";
import { toast } from "react-toastify";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import moment from "moment";
import DatePicker from "react-datepicker";
import { addDays } from "../../utilities/date.utilities";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { ReactSelectOption } from "../users/login-application-select/login-application-select.component";
import { UserSlimDisplay } from "../../components/user-slim-display/user-slim-display";

type DwsimProLicensesTableProps = {

}

type UsersUsageDataState = {
    [username: string]: UsersUsageDataModel
}

type UsersUsageDataModel = {
    isLoading: boolean,
    data?: DwsimProUsagePerUserModel
}
type DwsimProLicenseModelProperties = keyof DwsimProLicenseModel | string;

const getTodayDate = () => {
    let dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0);
    return dateNow;
}

const getDefaultStartDate = () => {

    return addDays(getTodayDate(), -30);
}
const getDefaultEndDate = () => {
    let tomorrow = addDays(new Date(), 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow;

}

const organizationTypeOptions: ReactSelectOption[] = [
    {
        label: "Company", value: OrganizationType.Company
    },
    {
        label: "University", value: OrganizationType.University
    },
    {
        label: "Consulting", value: OrganizationType.Consulting
    }
];

const planTypeOptions: ReactSelectOption[] = [
    {
        label: "Paid", value: 1
    },
    {
        label: "Trial", value: 0
    }
];

const hasAccessedDwsimProOptions: ReactSelectOption[] = [
    {
        label: "Logged in", value: 1
    },
    {
        label: "Not logged in", value: 0
    }
];

export const DwsimProLicensesTable: React.FC<DwsimProLicensesTableProps> = (props) => {

    const [licenses, setLicenses] = React.useState<DwsimProLicenseModel[]>([]);
    const [hideTestUsers, setHideTestUsers] = React.useState<boolean>(true);
    const [hideConsumers, setHideConsumers] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [pagination, setPagination] = React.useState<IPagination>({
        currentPage: 1,
        recordsPerPage: 25,
        totalRecords: 0
    });
    const [orderBy, setOrderBy] = React.useState<DwsimProLicenseModelProperties>("plan");
    const [orderByDescending, setOrderByDescending] = React.useState<boolean>(false);
    const [numberOfPaidUsers, setNumberOfPaidUsers] = React.useState<number>();
    const [numberOfTrialUsers, setNUmberOfTrailUsers] = React.useState<number>();
    const [organizationType, setOrganizationType] = React.useState<OrganizationType>();
    const [fromDate, setFromDate] = React.useState<Date>(getDefaultStartDate());
    const [toDate, setToDate] = React.useState<Date>(getDefaultEndDate());
    const [showOnlyPaid, setShowOnlyPaid] = React.useState<boolean>();
    const [hasAccessedDwsimPro, setHasAccessedDwsimPro] = React.useState<boolean>();

    // React.useEffect(() => {
    //     getDwsimProUsers();
    // }, []);

    React.useEffect(() => {
        getDwsimProUsers();
    }, [pagination.currentPage, pagination.recordsPerPage, orderBy, orderByDescending]);
    const getDwsimProUsers = async () => {
        try {
            //LoadingService.showLoading();
            setIsLoading(true);

            const client = getDwsimProClient();
            const fromDateUtc = fromDate ? new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate())) : undefined;
            const toDateUtc = toDate ? new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())) : undefined;
            const skip = (pagination.currentPage - 1) * pagination.recordsPerPage;
            const take = pagination.recordsPerPage;
            const usersResp = await client.getDwsimProLicenses(hideTestUsers, hideConsumers, organizationType, hasAccessedDwsimPro, fromDateUtc, showOnlyPaid, toDateUtc, orderBy, orderByDescending, true, skip, take);
            console.log("usersResp", usersResp);
            setLicenses(usersResp.items);
            setPagination(s => ({ ...s, totalRecords: usersResp.totalRecords }));


            setNumberOfPaidUsers(usersResp.paidUsersCount);
            setNUmberOfTrailUsers(usersResp.trialUsersCount);
            setIsLoading(false);


        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while getting DWSIM Pro users.");
        } finally {
            //  LoadingService.hideLoading();
            setIsLoading(false);
        }
    }


    const getSortValue = (columnName: DwsimProLicenseModelProperties) => {

        if (orderBy == columnName) {
            if (orderByDescending) {
                return "desc";
            } else {
                return "asc";
            }
        }

        return "";
    }
    const onColumnClick = (column: DwsimProLicenseModelProperties) => {
        if (orderBy == column) {
            setOrderByDescending(!orderByDescending);
        } else {
            setOrderBy(column);
            setOrderByDescending(true);
        }
    }


    const onTodayClick = () => {
        setFromDate(getTodayDate());
        setToDate(getDefaultEndDate());

    }
    const onLast7DaysClick = () => {
        const startDate = addDays(getTodayDate(), -7);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }
    const onLast30DaysClick = () => {
        const startDate = addDays(getTodayDate(), -30);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }

    const onLastMonthClick = () => {
        const dateNow = new Date();
        const firstInMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        let firstInNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        firstInNextMonth.setMonth(firstInMonth.getMonth() + 1);
        const lastDayOfMonth = addDays(firstInNextMonth, -1);
        setFromDate(firstInMonth);
        setToDate(lastDayOfMonth);

    }

    const onLastYearClick = () => {
        const dateNow = new Date();
        const januaryFirst = new Date(dateNow.getFullYear(), 0, 1, 0, 0, 0);
        setFromDate(januaryFirst);
        setToDate(getDefaultEndDate());

    }

    const onAddDayClick = (numberOfDays: number) => {
        const newDate = addDays(fromDate ?? new Date(), numberOfDays);
        setFromDate(newDate);
    }
    const onAddMonthClick = (numberOfMonths: number) => {
        let newDate = !!fromDate ? new Date(fromDate) : new Date();
        newDate.setMonth(newDate.getMonth() + numberOfMonths);
        setFromDate(newDate);
    }

    const selectedorganizationType = organizationType !== undefined ? organizationTypeOptions.find(x => +x.value! == +organizationType) : undefined;
    const selectedPlanType = showOnlyPaid !== undefined ? planTypeOptions.find(x => !!x.value == showOnlyPaid) : undefined;
    const selectedHasAccessedDwsimPro = hasAccessedDwsimPro !== undefined ? hasAccessedDwsimProOptions.find(x => !!x == hasAccessedDwsimPro) : undefined;

    const rightItems = [
        <div className="pr-4 text-center" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRight: "1px solid lightgrey" }}>
            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{numberOfPaidUsers ?? "-"}</b>
            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Paid users</span>
        </div>,
        <div className="ml-4 text-center mr-2" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{numberOfTrialUsers ?? "-"}</b>
            <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Trial users</span>
        </div>,
        <div style={{ minWidth: "75px" }}>
            <select
                className="form-control form-select"
                value={pagination.recordsPerPage}
                onChange={(event) => setPagination(s => ({ ...s, recordsPerPage: +event.target.value }))}>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
            </select>
        </div>
    ];

    const leftItems = [

        <div className="col-auto mr-1">
            <Form.Check type="checkbox"
                checked={hideTestUsers}
                onChange={(ev: any) => { setHideTestUsers(ev.target.checked); }}
                label="Hide test users" />
                <Form.Check type="checkbox"
                checked={hideConsumers}
                onChange={(ev: any) => { setHideConsumers(ev.target.checked); }}
                label="Hide consumers" />
        </div>,
       
        <div className="col-auto mr-1  p-0" style={{ minWidth: "100px" }}>
            <Select
                value={selectedPlanType}
                placeholder={"Plan type"}
                isMulti={false}
                isClearable
                options={planTypeOptions}
                onChange={(newValue) => {
                    const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? !!newValue.value : undefined;
                    setShowOnlyPaid(value);
                }}
            />
        </div>,
        <div className="col-auto mr-1  p-0" style={{ minWidth: "100px" }}>
            <Select
                value={selectedHasAccessedDwsimPro}
                placeholder={"Dwsim Pro access"}
                isMulti={false}
                isClearable
                options={hasAccessedDwsimProOptions}
                onChange={(newValue) => {
                    const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? !!newValue.value : undefined;
                    setHasAccessedDwsimPro(value);
                }}
            />
        </div>,
        <div className="col-auto mr-1 p-0" style={{ minWidth: "200px" }}>
            <Select
                value={selectedorganizationType}
                placeholder={"Organization type"}
                isMulti={false}
                isClearable
                options={organizationTypeOptions}
                onChange={(newValue) => {
                    const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? +newValue.value : undefined;
                    setOrganizationType(value);
                }}
            />
        </div>,
        <div className="input-group mr-2" style={{ width: '380px' }}>
            <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
            <div className="input-group__datepicker-wrapper">
                <DatePicker
                    id="fromDate"
                    className="form-control"
                    selected={fromDate}
                    onChange={(date: Date) => {
                        if (!date) { setFromDate(undefined); }
                        else { setFromDate(new Date(Date.parse(date != null ? date.toString() : ""))) }
                    }}
                    showTimeSelect
                    maxDate={toDate}
                    placeholderText="Date from"
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm" />
            </div>
            <span className="input-group-text">-</span>

            <div className="input-group__datepicker-wrapper">
                <DatePicker
                    id="toDate"
                    className="form-control"
                    selected={toDate}
                    onChange={(date: Date) => {
                        if (!date) { setToDate(undefined); }
                        else { setToDate(new Date(Date.parse(date != null ? date.toString() : ""))) }
                    }}
                    showTimeSelect
                    maxDate={new Date()}
                    placeholderText="Date to"
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm" />
            </div>
            <Dropdown>
                <Dropdown.Toggle variant="primary" className="datePickerWithDropdown" id="dropdown-basic" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={onTodayClick} >Today</Dropdown.Item>
                    <Dropdown.Item onClick={onLast7DaysClick}>Last 7 days</Dropdown.Item>
                    <Dropdown.Item onClick={onLast30DaysClick}>Last 30 days</Dropdown.Item>
                    <Dropdown.Item onClick={onLastMonthClick}>This month</Dropdown.Item>
                    <Dropdown.Item onClick={onLastYearClick}>This year</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddDayClick(1) }}>+ day</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddDayClick(-1) }}>- day</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddMonthClick(1) }}>+ month</Dropdown.Item>
                    <Dropdown.Item onClick={() => { onAddMonthClick(-1) }}>- month</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>


        </div>,
        <div className="col-auto">
            <button type="button" className="btn btn-primary" onClick={() => {
                if (pagination.currentPage !== 1) {
                    setPagination(s => ({ ...s, currentPage: 1 }));
                } else {
                    getDwsimProUsers();
                }
            }} >Search</button>
        </div>

    ];
    const totalPages = Math.ceil(pagination.totalRecords / pagination.recordsPerPage);


    return <><SubHeader key="sub-header" title="DWSIM Pro Licenses" leftItems={leftItems} rightItems={rightItems} heightAuto />
        <ContentWrapper key="content-wrapper" className="pl-4 pr-4">
            <Card>
                <Card.Body>

                    <div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className={"align-middle sortable " + getSortValue("usernameWithoutDomain")}
                                        onClick={() => { onColumnClick("usernameWithoutDomain") }}>User</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("plan")}
                                        onClick={() => { onColumnClick("plan") }}>Plan</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("portalSource")}
                                        onClick={() => { onColumnClick("portalSource") }}>Source</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("hasSubmittedOrganizationMail")}
                                        onClick={() => { onColumnClick("hasSubmittedOrganizationMail") }}>Org. email</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("createdAt")}
                                        onClick={() => { onColumnClick("createdAt") }}>Started at</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("expiresAt")}
                                        onClick={() => { onColumnClick("expiresAt") }}>Expires at</th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last7Days.daysUsed")}
                                        onClick={() => { onColumnClick("last7Days.daysUsed") }}>
                                        <span>Days used</span><br />
                                        <span>(-7)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last7Days.screenshotsFound")}
                                        onClick={() => { onColumnClick("last7Days.screenshotsFound") }}>
                                        <span>Screenshots</span><br />
                                        <span>(-7)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last30Days.daysUsed")}
                                        onClick={() => { onColumnClick("last30Days.daysUsed") }}>
                                        <span>Days used</span><br />
                                        <span>(-30)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last30Days.screenshotsFound")}
                                        onClick={() => { onColumnClick("last30Days.screenshotsFound") }}>
                                        <span>Screenshots</span><br />
                                        <span>(-30)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last365Days.daysUsed")}
                                        onClick={() => { onColumnClick("last365Days.daysUsed") }}>
                                        <span>Days used</span><br />
                                        <span>(-365)</span>
                                    </th>
                                    <th className={"align-middle text-center sortable " + getSortValue("last365Days.screenshotsFound")}
                                        onClick={() => { onColumnClick("last365Days.screenshotsFound") }}>
                                        <span>Screenshots</span><br />
                                        <span>(-365)</span>
                                    </th>

                                    <th className={"align-middle text-center sortable " + getSortValue("lastVisit")}
                                        onClick={() => { onColumnClick("lastVisit") }}>Last visit</th>
                                </tr>
                            </thead>
                            <tbody>

                                {!isLoading && licenses && licenses.length > 0 && licenses.map((license, i) => (
                                    <tr key={`${license.usernameWithoutDomain}-${i}`}
                                        className={license.plan == "Paid" ? "table-info" : undefined}
                                    >
                                        <td className="align-middle"><UserSlimDisplay user={license.user} showDetailsOnClick /></td>
                                        <td className="align-middle  text-center">{license.plan} </td>
                                        <td className="align-middle  text-center">{license.portalSource}</td>
                                        <td className="align-middle  text-center">{license.hasSubmittedOrganizationMail ? "YES" : "NO"}</td>
                                        <td className="align-middle  text-center">
                                            {!!license.createdAt ?
                                                <span>{moment(license.createdAt).format("DD.MM.YYYY")}</span>
                                                : "-"}
                                        </td>
                                        <td className="align-middle  text-center">
                                            {!!license.expiresAt ? <span>{moment(license.expiresAt).format("DD.MM.YYYY")}</span>
                                                : "-"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {license.last7Days?.daysUsed ?? "-"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {license.last7Days?.screenshotsFound ?? "-"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {license.last30Days?.daysUsed ?? "-"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {license.last30Days?.screenshotsFound ?? "-"}
                                        </td>
                                        <td className="align-middle text-center">
                                            {license.last365Days?.daysUsed ?? "-"}

                                        </td>
                                        <td className="align-middle text-center">
                                            {license.last365Days?.screenshotsFound ?? "-"}

                                        </td>
                                        <td className="align-middle text-center">
                                            {!!license.lastVisit ? <DateTimeDisplayUTC date={license.lastVisit} hideTime /> : "-"}
                                        </td>


                                    </tr>))
                                }

                                {isLoading &&
                                    <tr>
                                        <td colSpan={14} className="text-center">Loading...</td>
                                    </tr>
                                }
                                {!isLoading && licenses.length == 0 &&
                                    <tr>
                                        <td colSpan={14} className="text-center">No users found.</td>
                                    </tr>
                                }


                            </tbody>
                        </table>

                        {!isLoading && pagination.totalRecords > 1 &&
                            <div className="d-flex align-items-center">
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={10}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    forcePage={pagination.currentPage - 1}
                                    onPageChange={(page) => {
                                        console.log("Selected page:", page);
                                        setPagination(s => ({ ...s, currentPage: page.selected + 1 }));
                                        document.body.scrollTop = 0; // For Safari
                                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                    }}
                                    containerClassName={'pagination mb-0'}
                                    activeClassName={'active'}
                                />
                            </div>
                        }

                    </div>


                </Card.Body>
            </Card>
        </ContentWrapper></>
}

type UserUsageDataValueProps = {
    property: string;
    username: string;
    isDateTime?: boolean;
    usersUsageData: { [username: string]: { isLoading: boolean, data?: DwsimProUsagePerUserModel } }
}



const UserUsageDataValue: React.FC<UserUsageDataValueProps> = (props): JSX.Element => {

    const usageData = props.usersUsageData[props.username];
    const propertyNameSplit = props.property.split(".");


    if (!usageData) return <span key={`${props.username}-${props.property}`}>-</span>;

    if (usageData.isLoading) return <span key={`${props.username}-${props.property}`}>Loading...</span>;

    if (!usageData.data) return <span key={`${props.username}-${props.property}`}>-</span>;
    //@ts-ignore
    const propertyValue = propertyNameSplit.length > 1 ? usageData.data[propertyNameSplit[0]]?.[propertyNameSplit[1]] : usageData.data[propertyNameSplit[0]];

    if (props.isDateTime) {
        return <span key={`${props.username}-${props.property}`}>{propertyValue ? <DateTimeDisplayUTC date={propertyValue} hideTime /> : "-"}</span>;
    }

    return <span key={`${props.username}-${props.property}`}>{propertyValue ?? "-"}</span>;
}

