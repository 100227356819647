import * as React from "react";
import { Modal } from "react-bootstrap";
import UserGeneralData from "./user-general-data.component";
import UserActiveAgents from "./user-active-agents.component";
import { UserLoginActivities } from "./user-login-activities.component";
import { DwsimPropUsage } from "./dwsim-pro-usage.component";
import DeleteUserModal from "./delete-user-modal.component";


interface IUserDetailsModalProps {
    userId: string;
    userPrincipalName?: string;
    userDisplayName?: string;
    show: boolean;
    onHide: () => void;
    onMotherTicketChanged?: (motherTicketId: number, userId: string) => void;
    onReloadUsers?: () => void;
}



const UserDetailsModal: React.FunctionComponent<IUserDetailsModalProps> = (props) => {

    const [selectedTab, setSelectedTab] = React.useState<number>(1);
    const [showDeleteUserModal, setShowDeleteUserModal] = React.useState<boolean>(false);




    return <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
        size="xl"
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                User details
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>

            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className={`nav-link ${selectedTab == 1 ? "active" : ""}`} onClick={() => setSelectedTab(1)} href="#">General data</a>
                </li>

                <li className="nav-item">
                    <a className={`nav-link ${selectedTab == 2 ? "active" : ""}`} onClick={() => setSelectedTab(2)} href="#">Active agents</a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${selectedTab == 3 ? "active" : ""}`} onClick={() => setSelectedTab(3)} href="#">Login activities</a>
                </li>
                {props.userPrincipalName && <li className="nav-item">
                    <a className={`nav-link ${selectedTab == 4 ? "active" : ""}`} onClick={() => setSelectedTab(4)} href="#">DWSIM PRO usage</a>
                </li>}

            </ul>
            {selectedTab == 1 && <UserGeneralData userId={props.userId} onMotherTicketChanged={props.onMotherTicketChanged} />
            }

            {selectedTab == 2 && <UserActiveAgents userId={props.userId} />}

            {selectedTab == 3 && <UserLoginActivities userId={props.userId} />}
            {selectedTab == 4 && <DwsimPropUsage userPrincipalName={props.userPrincipalName} />}

        </Modal.Body>

        <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
                <div className="form-group d-flex">
                    <button type="button" onClick={() => { setShowDeleteUserModal(true) }} className={'btn btn-danger ml-2'}>
                        Delete
                    </button>
                </div>


                <div className="form-group d-flex">
                    <button type="button" onClick={props.onHide} className={'btn btn-info ml-2'}>
                        Close
                    </button>
                </div>
            </div>
        </Modal.Footer>

        {showDeleteUserModal &&
            <DeleteUserModal
                show={showDeleteUserModal}
                userId={props.userId}
                userDisplayName={props.userDisplayName}
                onSuccess={() => { setShowDeleteUserModal(false); props.onReloadUsers(); }}
                onHide={() => { setShowDeleteUserModal(false) }} />}

    </Modal>
}


export default UserDetailsModal;