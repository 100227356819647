import React from "react";
import targetProcessLogo from "../../assets/images/target-process-logo.png";
import BootstrapTooltip from "../bootstrap-tooltip/bootstrap-tooltip";

type TargetProcessLinkProps = {
    targetProcesMotherTicketId: number;
    ticketName?: string;
    nameAsTooltip?: boolean,
    totalUsersCount?: number;
    isUniversity?: boolean;
    isConsulting?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

export const TargetProcessLink: React.FC<TargetProcessLinkProps> = (props) => {


    return <div className={'d-flex align-items-center ' + props.className}>
        <a href={`https://capitalgain.tpondemand.com/entity/${props.targetProcesMotherTicketId}`}
            target="_blank"
            onClick={(ev) => ev.stopPropagation()}
            style={props.style}
        >
            <img height={"20px"} src={targetProcessLogo} alt="Target process logo" />
            <span className="ml-2">
                {!props.nameAsTooltip && (!!props.ticketName ? props.ticketName : `#${props.targetProcesMotherTicketId}`)}                
                {props.nameAsTooltip && <BootstrapTooltip tooltipText={props.ticketName}><span>{props.targetProcesMotherTicketId}</span></BootstrapTooltip>}
            </span>
        </a>
        {props.totalUsersCount !==undefined && <span className="ml-1">({props.totalUsersCount})</span>}
        {props.isUniversity && <BootstrapTooltip tooltipText="University"><span className="ml-2"><i className="fas fa-graduation-cap" style={{ color: "black" }}></i></span></BootstrapTooltip>}
        {props.isConsulting && <BootstrapTooltip tooltipText="Consulting"><span className="ml-2"><i className="fas fa-briefcase" style={{ color: "black" }}></i></span></BootstrapTooltip>}
    </div>
}