import React, { ReactNode } from 'react';
import { Tooltip, OverlayTrigger, OverlayTriggerProps, TooltipProps } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';

// Define the props for the WithTooltip component
interface BootstrapTooltipProps {
    tooltipText: string | React.ReactElement; // Text displayed inside the tooltip
    placement?: OverlayTriggerProps['placement']; // Placement of the tooltip (top, right, bottom, left)
    delay?: OverlayTriggerProps['delay']; // Delay for showing/hiding the tooltip
    children: React.ReactElement; // The element or component to wrap
}

const BootstrapTooltip: React.FC<BootstrapTooltipProps> = ({
    tooltipText,
    placement = 'top',
    delay = { show: 250, hide: 400 },
    children,
}) => {
    const renderTooltip = (props: TooltipProps) => (
        <Tooltip  {...props}>
            {tooltipText}
        </Tooltip>
    );

    return (
        <OverlayTrigger placement={placement} delay={delay} overlay={renderTooltip as any}>
            {children}
        </OverlayTrigger>
    );
};

export default BootstrapTooltip;
