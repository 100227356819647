import React, { EventHandler, HtmlHTMLAttributes, useCallback, useState } from "react";
import { UserActivityLevelBadge } from "../../pages/users/user-activity-level-badge.component";
import BootstrapTooltip from "../bootstrap-tooltip/bootstrap-tooltip";
import { TargetProcessLink } from "../target-process-link/target-process-link.component";
import { UserSlimModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import UserDetailsModal from "../../pages/users/user-details-modal/user-details-modal.component";

type UserSlimDisplayProps = {
    user?: UserSlimModel,
    showDetailsOnClick?: boolean,
};

export const UserSlimDisplay = ({ user, showDetailsOnClick }: UserSlimDisplayProps) => {

    const [showDetailsModal, setShowDetailsModal] = useState(false);

    if (!user)
        return <span>Loading...</span>;

    return <div className="d-flex align-items-center">
        <UserActivityLevelBadge userActivityLevel={user.activityLevel} size={30} />
        <div className="ml-2 d-flex flex-column">
            {!showDetailsOnClick && <span>{user.displayName}</span>}
            {showDetailsOnClick && <a href="#" onClick={(e) => { e.preventDefault(); setShowDetailsModal(true); }}>{user.displayName}</a>}
            <small>{user.email}</small>
            {user.businessEmail && user.businessEmail != user.email && <small>{user.businessEmail}</small>}
        </div>
        {user.motherTickets?.map(mt => <TargetProcessLink className="ml-4 d-flex align-items-center" targetProcesMotherTicketId={mt.id} ticketName={mt.name} nameAsTooltip totalUsersCount={mt.totalUsersCount} isUniversity={mt.isUniversity} isConsulting={mt.isConsulting} />)}

        {showDetailsModal && <UserDetailsModal
            show={true}
            userId={user.id}
            //userDisplayName={selectedUser?.displayName ?? ""}
            //userPrincipalName={selectedUser?.userPrincipalName ?? ""}
            //onMotherTicketChanged={this.onMotherTicketCreated}
            //onReloadUsers={() => { this.setState({ showDetailsModal: false, selectedUser: undefined }, () => { this.getUsers(); }); }}
            onHide={() => setShowDetailsModal(false)} />
        }
    </div>
}