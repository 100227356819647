import { configureStore, Tuple } from '@reduxjs/toolkit';
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';
import createRootReducer from './root-reducer';




export const history = createBrowserHistory();

const middlewares = [logger];
const store = configureStore({
    reducer: createRootReducer(history),
    middleware: () => new Tuple(...middlewares),
})



export default store;
