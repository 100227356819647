import * as React from 'react';
import ContentWrapper from '../../layout/components/content-wrapper/content-wrapper.component';
import SubHeader from '../../layout/components/sub-header/sub-header.component';
import { LogMessageModel, ScreenshotsClient, ScreenshotsQuery } from '../../swagger-clients/s365-admin-panel-clients.service';
import { Alert, Button, ButtonGroup, Card, Dropdown, OverlayTrigger, Popover, PopoverContent, Spinner, ToggleButton } from 'react-bootstrap';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import ImageModal from "../../components/image-modal/image-modal.component";
import SidebarPlaceHolderService from "../../components/sidebar-placeholder/sidebar-placeholder-service";
import ScreenshotsSearch, { getDefaultScreenshotsFilter, ScreenshotsSearchFilter, ScreenshotsSearchType } from "./screenshots-search.component";
import { saveAs } from 'file-saver';
import "./screenshots.styless.scss";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { LoadingService } from "../../components/loading-indicator/loading-indicator.component";
import { getTelemetryScreenShotsClient, getUserToken } from '../../services/api-clients.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DownloadScreenshotsRequestModel } from '../../swagger-clients/telemetry-clients.service';
import { DownloadScreenshotsProgressDialog } from './download-screenshots-progress-dialog.component';
import { ShowErrorFromResponse } from '../../utilities/response-processor';
import queryString from "query-string";
import { useHistory, useLocation } from 'react-router';
import { useState } from 'react';

type IScreenShotsPageProps = {
    accessToken: string;
}
export interface IPagination {
    currentPage: number;
    totalRecords: number;
    recordsPerPage: number;
}


enum ViewState {
    Fullscreen,
    Cards
}

const ScreenshotsPage: React.FC<IScreenShotsPageProps> = (props) => {


    const shiftPressed = React.useRef<boolean>(false);
    const location = useLocation();
    const history = useHistory();

    const [searchFilter, setSearchFilter] = React.useState<ScreenshotsSearchFilter>(getDefaultScreenshotsFilter());

    const [logMessages, setLogMessages] = useState<LogMessageModel[]>([]);
    const [totalRecords, setTotalRecords] = React.useState<number>(0);

    const [selectedLog, setSelectedLog] = useState<LogMessageModel | undefined>(undefined);
    const [selectedLogIndex, setSelectedLogIndex] = useState<number | undefined>(undefined);
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [selectedMessages, setSelectedMessages] = useState<LogMessageModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [view, setView] = useState<ViewState>(ViewState.Cards);
    const [downloadRequestId, setDownloadRequestId] = useState<string | undefined>(undefined);
    const [showDownloadScreenshotsProgressDialog, setShowDownloadScreenshotsProgressDialog] = useState<boolean>(false);


    React.useEffect(() => {
        console.log("ScreenshotsPage on Mount.");
        registerShiftKeyEvents();
        updateBodyTagId("screenshoots-page");
        LoadingService.addCallback((isLoading: boolean) => {
            setIsLoading(isLoading);
        });
        loadFilterParametersFormSearchQuery();

        return () => {
            updateBodyTagId("");
            SidebarPlaceHolderService.setContent(<></>);
        }
    }, []);

    const loadFilterParametersFormSearchQuery = async () => {
        const filterFromQuery = getFiltersFromQueryParams(getDefaultScreenshotsFilter());
        await getLogMessages(filterFromQuery);
    }



    const updateSidebarPlaceholder = (activeUsers: string[], hasMaxResponsesReached: boolean) => {

        SidebarPlaceHolderService.setContent(
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ padding: '9px 25px', fontWeight: 'bold', backgroundColor: 'rgb(243, 246, 249)' }}><i className="fas fa-user menu-icon" style={{ color: "#3699FF", marginRight: '12px' }} /> Filter by Username</div>
                <div style={{ flex: '1 1 auto', overflowY: "auto", height: '0px' }}>
                    <ul className='p-0 m-3' style={{ listStyle: "none" }}>
                        {hasMaxResponsesReached && <li className="alert alert-warning p-1 text-center" style={{ margin: "10px", fontSize: '0.8em' }}>
                            Maximum of 10000 results reached which will affect accuracy of data.
                        </li>}
                        {
                            activeUsers.map(user => {
                                return <li><button className="btn btn-link p-0"
                                    onClick={() => onUsernameClick(user)}>
                                    {user} </button></li>
                            })
                        }
                    </ul>
                </div>
            </div>);
    }



    const registerShiftKeyEvents = () => {
        document.addEventListener('keydown', (event) => {
            if (event.key === "Shift") {
                if (!shiftPressed.current) {
                    shiftPressed.current = true;
                }

            }
        });

        document.addEventListener('keyup', (event) => {
            if (event.key === "Shift") {
                shiftPressed.current = false;
            }
        });
    }

    const updateUrl = (filter: ScreenshotsSearchFilter) => {
        const queryParams = getQueryString(filter, getDefaultScreenshotsFilter());
        console.log("search, queryParams", location.search, queryParams);
        const newQueryParms = `?${queryParams}`;
        if (location.search !== newQueryParms)
            history.replace(newQueryParms);
    };

    const getFiltersFromQueryParams = (defaultValues: ScreenshotsSearchFilter) => {
        const params: ScreenshotsSearchFilter = queryString.parse(location.search, {
            parseNumbers: true,
            parseBooleans: true
        }) as any;

        let filters = { ...defaultValues };

        for (let k of Object.keys(params)) {
            (filters as any)[k] = (params as any)[k];
        }
        if (!!filters.fromDate) {
            filters.fromDate = new Date(filters.fromDate);
        }
        if (!!filters.toDate) {
            filters.toDate = new Date(filters.toDate);
        }


        return filters;
    }

    const getQueryString = (filters: ScreenshotsSearchFilter, defaultValues: ScreenshotsSearchFilter): string => {
        let clone: ScreenshotsSearchFilter = { ...filters };

        for (let k of Object.keys(clone)) {
            if ((clone as any)[k] == (defaultValues as any)[k])
                delete (clone as any)[k];
        }

        // Hack 
        if (!!clone.toDate)
            clone.toDate = clone.toDate.toISOString() as any;
        if (!!clone.fromDate)
            clone.fromDate = clone.fromDate.toISOString() as any;

        const queryParams = queryString.stringify(clone as any, {
            skipNull: true,
            skipEmptyString: true
        });

        return queryParams;
    }

    const getLogMessages = async (filterValue?: ScreenshotsSearchFilter) => {

        const filter = !!filterValue ? filterValue : searchFilter;
        setSearchFilter(filter);

        const { user, computerName, fromDate, toDate, page: currentPage, recordsPerPage } = filter;
        updateUrl({
            user,
            computerName,
            fromDate,
            toDate,
            page: currentPage,
            recordsPerPage
        } as ScreenshotsSearchFilter);


        try {
            LoadingService.showLoading();
            const client = new ScreenshotsClient(process.env.REACT_APP_BASE_URL);
            const startDateUtc = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes(), fromDate.getSeconds()));
            const endDateUtc = new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), toDate.getSeconds()));

            const query = {
                username: user,
                computerName: computerName,
                fromDate: startDateUtc,
                toDate: endDateUtc,
                take: recordsPerPage,
                skip: (currentPage - 1) * recordsPerPage
            } as unknown as ScreenshotsQuery;
            const logMessagesResponse = await client.getScreenShots(query);

            console.log("get screenshots logMessagesResponse", logMessagesResponse);
            setLogMessages(logMessagesResponse?.messages ?? []);
            setSelectedLog(
                selectedLogIndex !== undefined ? logMessagesResponse.messages[selectedLogIndex] : undefined
            );
            setTotalRecords(logMessagesResponse.totalResults ?? 0);

            updateSidebarPlaceholder(logMessagesResponse.activeUsers ?? [], logMessagesResponse.maxResponsesReached ?? false)

        } catch (error) {
            ShowErrorFromResponse(error, "An error ocurred while loading GrayLog messages with screenshots data.");
        } finally {
            LoadingService.hideLoading();
        }


    }


    const SetCurrentPage = (page: number) => {
        setSearchFilter(s => ({
            ...s,
            page: page
        }));
    }
    const onLogMessageClicked = (logMessage: LogMessageModel, logMessageIndex: number) => {
        if (shiftPressed.current) {
            toggleSelectedMessage(logMessage);
        } else {
            setSelectedLog(logMessage);
            setSelectedLogIndex(logMessageIndex);
            setShowImageModal(true);
        }
    }

    const toggleSelectedMessage = (logMessage: LogMessageModel) => {

        const existingMessage = selectedMessages.find(value => value.screenshotFileName == logMessage.screenshotFileName);
        if (existingMessage) {
            const newMessages = selectedMessages.filter(value => value.screenshotFileName !== logMessage.screenshotFileName);
            setSelectedMessages(newMessages);

        } else {
            setSelectedMessages([...selectedMessages, logMessage]);
        }
        console.log("Selected messages", selectedMessages);
    }

    const isMessageSelected = (message: LogMessageModel): boolean => {

        const selectedMessage = selectedMessages.find((value) => value.screenshotFileName == message.screenshotFileName);

        return !(selectedMessage === undefined);

    }

    const updateBodyTagId = (value: string) => {
        var body = document.getElementsByTagName("body")[0];
        body.id = value;
    }


    const requestToDownloadScreenshots = async () => {

        try {
            if (selectedMessages.length > 0) {
                LoadingService.showLoading();
                const client = getTelemetryScreenShotsClient();
                const model = new DownloadScreenshotsRequestModel({
                    filenames: selectedMessages.map(m => m.screenshotFileName)
                });
                const resp = await client.submitGenerateZipRequest(model);
                setDownloadRequestId(resp?.requestId);
                setShowDownloadScreenshotsProgressDialog(true);



            } else {
                toast.error("No selected images.");
            }
        }
        catch (error) {
            toast.error(`An error ocurred while trying to submit request to download screenshots.`);
        }
        finally {
            LoadingService.hideLoading();
        }
    }
    const onItemPerPageChanged = (recordsPerPageNew: number) => {
        setSearchFilter(s => ({
            ...s,
            page: 1,
            recordsPerPage: recordsPerPageNew,
        }));
    }

    const onUsernameClick = async (username: string) => {
        await getLogMessages({ ...searchFilter, user: username, page: 1 } as ScreenshotsSearchFilter);
    }

    const onComputernameClick = async (computerName: string) => {
        await getLogMessages({ ...searchFilter, computerName: computerName, page: 1 } as ScreenshotsSearchFilter);
    }
    const onPreviousImageClick = async () => {

        if (selectedLogIndex == undefined) return;

        if (selectedLogIndex - 1 >= 0) {
            setSelectedLog(logMessages[selectedLogIndex - 1]);
            setSelectedLogIndex(selectedLogIndex - 1);
        }
        else if (selectedLogIndex == 0) {
            if (searchFilter.page > 1) {
                await getLogMessages({ ...searchFilter, page: searchFilter.page - 1 } as ScreenshotsSearchFilter);
                setSelectedLogIndex(searchFilter.recordsPerPage - 1);
            }
        }
    }

    const onNextImageClick = async () => {

        if (selectedLogIndex == undefined) return;

        if (selectedLogIndex + 1 <= logMessages.length - 1) {
            setSelectedLog(logMessages[selectedLogIndex + 1]);
            setSelectedLogIndex(selectedLogIndex + 1);
        }
        else if (selectedLogIndex + 1 == logMessages.length) {
            const totalPages = Math.ceil(totalRecords / searchFilter.recordsPerPage);
            if (totalPages > searchFilter.page) {
                SetCurrentPage(searchFilter.page + 1);
                setSelectedLogIndex(0);
            }
        }
    }




    const totalPages = Math.ceil(totalRecords / searchFilter.recordsPerPage);
    const rightItems = [<Dropdown as={ButtonGroup}>
        <Button disabled={!(selectedMessages.length > 0)} onClick={requestToDownloadScreenshots} variant="primary">Download scr.</Button>

        <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

        <Dropdown.Menu>
            <Dropdown.Item onClick={() => { setSelectedMessages([...logMessages]) }}>Select all</Dropdown.Item>
            <Dropdown.Item onClick={() => { setSelectedMessages([]) }}>Deselect all</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>];



    const leftItems = [
        <ButtonGroup className='toogle-buttons-custom'>
            <ToggleButton
                key="cards"
                id={`radio-cards`}
                type="radio"
                variant={'outline-primary'}
                size='sm'
                name="radio"
                value={view}
                checked={view === ViewState.Cards}
                onChange={(e) => setView(ViewState.Cards)}
            >
                <i className="bi bi-grid-3x3-gap"></i>
            </ToggleButton>
            <ToggleButton
                key="fullscreen"
                id={`radio-fullscreen`}
                type="radio"
                variant={'outline-primary'}
                size='sm'
                name="radio"
                value={view}
                checked={view === ViewState.Fullscreen}
                onChange={(e) => setView(ViewState.Fullscreen)}
            >
                <i className="bi bi-view-stacked"></i>
            </ToggleButton>
        </ButtonGroup>
    ];





    return <div data-page-title="screenshots-page">
        <SubHeader key="sub-header" title="Log screenshots" leftItems={leftItems} rightItems={rightItems} />
        <ContentWrapper key="content-wrapper">
            <Card className='mb-4' style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                <ScreenshotsSearch key="ScreenshotsSearch"
                    filter={searchFilter}
                    selectedMessages={selectedMessages}
                    getLogMessages={async () => await getLogMessages()}
                    totalRecords={totalRecords}
                    onFilterChange={(value) => { setSearchFilter(value) }}
                    onSearchClick={async (value) => { await getLogMessages(value) }}
                />
            </Card>
            {logMessages.length > 0 && <Card style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                <Card.Body>

                    <div className="row">
                        {
                            logMessages.map((message, messageIndex) => {
                                return <div key={`${message.id}-${messageIndex}`}
                                    className={view == ViewState.Cards ? "col-sm-12 col-md-4 col-lg-3 col-xl-2" : "col-sm-12"}
                                >

                                    <div className="card" style={{ margin: "5px", userSelect: "none" }}>
                                        <span className="far fa-check-circle item-selected"
                                            onClick={() => toggleSelectedMessage(message)}
                                            style={{ color: isMessageSelected(message) ? "green" : "lightgray", cursor: "pointer" }}></span>
                                        <div className="card-body" style={{ padding: "5px" }}>
                                            <h5 className="card-title mb-0">
                                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                                    <span>{moment(message.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')}</span>
                                                </OverlayTrigger> &nbsp;
                                                <a href={message.logUrl} target="_blank">
                                                    <i className="fas fa-external-link-alt" style={{ fontSize: "13px", marginLeft: "5px" }} />
                                                </a>
                                            </h5>
                                            <div>by <button className="btn btn-link p-0 m-0"
                                                onClick={() => onUsernameClick(message.user_name)}>
                                                {message.user_name} </button></div>
                                            <div className="mb-2">on <button className="btn btn-link p-0 m-0"
                                                onClick={() => onComputernameClick(message.computerName)}>
                                                {message.computerName}</button>
                                            </div>
                                            <div style={{ position: "relative", minHeight: "100px" }}>


                                                <LazyLoadImage
                                                    alt="screenshot"
                                                    placeholder={<Spinner animation={'border'} style={{ position: "absolute", top: "calc(50% - 18px)", left: "calc(50% - 18px)" }}
                                                    />}
                                                    onClick={() => { onLogMessageClicked(message, messageIndex); }}
                                                    style={{ maxWidth: "100%", maxHeight: "100%", cursor: "pointer" }}
                                                    src={`${process.env.REACT_APP_TELEMETRY_SERVICE_URL}/api/screenshots/${message.screenshotFileName}?access_token=${props.accessToken}`} />

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>


                    {totalPages > 1 &&
                        <div className="d-flex align-items-center">
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me page-item'}
                                breakLinkClassName={'page-link'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                pageCount={totalPages}
                                marginPagesDisplayed={4}
                                pageRangeDisplayed={10}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                forcePage={searchFilter.page - 1}
                                onPageChange={async (page) => {
                                    console.log("Selected page:", page);
                                    await getLogMessages({ ...searchFilter, page: page.selected + 1 } as ScreenshotsSearchFilter);
                                    document.body.scrollTop = 0; // For Safari
                                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                }}
                                containerClassName={'pagination mb-0'}
                                activeClassName={'active'}
                            />
                            {totalRecords >= 10000 && <i className="fas fa-exclamation-triangle text-danger" style={{ fontSize: "21px", padding: "5px" }}
                                title="Pagination will only work for first 10,000 results. Reduce your time range." />}

                            <div className='ml-auto'>Displaying {searchFilter.recordsPerPage} of total {totalRecords} records.</div>
                        </div>
                    }

                    <ImageModal
                        title={<span style={{ fontWeight: 600 }}>
                            {moment(selectedLog?.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')}
                            <small style={{ color: "black" }}> by <em>{selectedLog?.user_name}</em> on <em>{selectedLog?.computerName}</em> </small>
                            <small>(<a href={selectedLog?.logUrl} target="_blank">Graylog</a>)</small>
                        </span>}
                        imgSrc={`${process.env.REACT_APP_TELEMETRY_SERVICE_URL}/api/screenshots/${selectedLog?.screenshotFileName}?access_token=${props.accessToken}`}
                        show={showImageModal}
                        onHide={() => { setSelectedLog(undefined); setShowImageModal(false); }}
                        onNextClick={() => { onNextImageClick() }}
                        onPreviousClick={() => { onPreviousImageClick() }}
                        showPreviousButton={!(searchFilter.page == 1 && selectedLogIndex == 0)}
                        showNextButton={!(searchFilter.page == totalPages && selectedLogIndex == logMessages.length - 1)}
                    />

                    {!!downloadRequestId &&
                        <DownloadScreenshotsProgressDialog
                            accessToken={props.accessToken}
                            show={showDownloadScreenshotsProgressDialog}
                            downloadRequestId={downloadRequestId!}
                            onHide={() => {
                                setDownloadRequestId(undefined);
                                setShowDownloadScreenshotsProgressDialog(false);
                            }}
                        />}

                </Card.Body>
            </Card>
            }
            {!isLoading && logMessages.length == 0 &&
                <Card>
                    <Card.Body className='text-center'>
                        It seems that no screenshots were found with the given filters.<br />
                        Please consider updating your filters and trying again.
                    </Card.Body>
                </Card>}
        </ContentWrapper>
    </div >

}

export const UtcTimePopover = (
    <Popover id="popover-positioned-top" >
        <span style={{ padding: "5px" }}> UTC</span>
    </Popover>
);

export default ScreenshotsPage;


