import React from "react";
import Select from "react-select";
import { UserActivityLevel } from "../../../swagger-clients/s365-admin-panel-clients.service";


type UserActivityLevelSelectProps = {
    values: UserActivityLevel[];
    className?: string;
    placeholder?: string;
    onChange: (selectedValues: (string | number)[] | undefined | null) => void;
}

export const UserActivityLevelSelect: React.FC<UserActivityLevelSelectProps> = (props) => {
    const [selectedValues, setSelectedValues] = React.useState<ReactSelectOption[]>([]);

    React.useEffect(() => {

        const values = props.values.map((value) => {
            const application = applicationOptions.find(x => +x.value! == +value);
            return application;
        }).filter(x => x !== undefined);

        setSelectedValues(values);

    }, [props.values]);
    return <Select
        className={props.className}
        value={selectedValues}
        isMulti
        placeholder={props.placeholder}
        options={applicationOptions}
        onChange={(newValues) => {
            const selectedValues = newValues?.filter(x => x !== undefined && x !== null).map(x => x.value) ?? undefined;
            props.onChange(selectedValues as any);
        }} />
}

export type ReactSelectOption = {
    value: string | number | undefined | null;
    label: string;
}

const applicationOptions: ReactSelectOption[] = [
    { value: UserActivityLevel.Green, label: "Green (Logged in 3+ times)" },
    { value: UserActivityLevel.Yellow, label: "Yellow (Logged in at least once)" },
    { value: UserActivityLevel.Orange, label: "Orange (confirmed registration)" },
    { value: UserActivityLevel.Red, label: "Red (Inactive)" }

];