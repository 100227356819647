import React from "react";
import { IPagination, UtcTimePopover } from "../screenshots/screenshots.component";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { getFeedbacksClient } from "../../services/api-clients.service";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import { Card, Dropdown, Form, OverlayTrigger } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { addDays } from "../../utilities/date.utilities";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import { FeedbackResponseModel, NpsCategory, UserType } from "../../swagger-clients/s365-admin-panel-clients.service";
import Select from "react-select";
import { ReactSelectOption } from "../users/login-application-select/login-application-select.component";
import BootstrapTooltip from "../../components/bootstrap-tooltip/bootstrap-tooltip";
import { UserSlimDisplay } from "../../components/user-slim-display/user-slim-display";

type FeebacksPageProps = {

}

const getTodayDate = () => {
    let dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0);
    return dateNow;
}

const getDefaultStartDate = () => {

    return addDays(getTodayDate(), -30);
}


const getDefaultEndDate = () => {
    let tomorrow = addDays(new Date(), 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow;

}
const userTypeOptions: ReactSelectOption[] = [
    {
        label: "Consumer", value: UserType.Consumer
    },
    {
        label: "Company", value: UserType.Company
    },
    {
        label: "University", value: UserType.University
    },
    {
        label: "Consultant", value: UserType.Consultant
    }
];

const npsCategoryOptions: ReactSelectOption[] = [
    {
        label: "Promoter", value: NpsCategory.Promoter
    },
    {
        label: "Passive", value: NpsCategory.Passive
    },
    {
        label: "Detractor", value: NpsCategory.Detractor
    }
];


const getTextBasedOnFeedbackScore = (score: number, detractor: string, pasive: string, promoter: string) => {
    if (score <= 6)
        return detractor;
    else if (score >= 7 && score <= 8)
        return pasive;
    else if (score >= 9)
        return promoter;
}

const getTextBasedOnOverallScore = (score: number, needsImprovement: string, good: string, great: string, excellent: string) => {
    if (score <= 0)
        return needsImprovement;
    else if (score >= 1 && score <= 29)
        return good;
    else if (score >= 30 && score <= 70)
        return great;
    else if (score >= 71)
        return excellent;
}

export const FeebacksPage: React.FC<FeebacksPageProps> = (props) => {

    const [feedbacks, setFeedbacks] = React.useState<FeedbackResponseModel[]>([]);
    const [pagination, setPagination] = React.useState<IPagination>({
        currentPage: 1,
        recordsPerPage: 20,
        totalRecords: 0
    });
    const [fromDate, setFromDate] = React.useState<Date>(getDefaultStartDate());
    const [toDate, setToDate] = React.useState<Date>(getDefaultEndDate());
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>();
    const [selectedFeedbackId, setSelectedFeedbackId] = React.useState<number>();
    const [npsScore, setNpsScore] = React.useState<number>();
    const [hideTestUsers, setHideTestUsers] = React.useState<boolean>(true);
    const [userType, setUserType] = React.useState<UserType>();
    const [npsCategory, setNpsCategory] = React.useState<NpsCategory>();


    React.useEffect(() => {
        getFeedbacks();
    }, [pagination.currentPage, pagination.recordsPerPage]);

    const getFeedbacks = async () => {
        try {
            setFeedbacks([]);
            setIsLoading(true);

            const { recordsPerPage, currentPage } = pagination;
            const client = getFeedbacksClient();
            const startDateUtc = fromDate ? new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes(), fromDate.getSeconds())) : null;
            const endDateUtc = toDate ? new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), toDate.getSeconds())) : undefined;
            const feedbacksResponse = await client.getFeedbacks(searchTerm, userType, npsCategory, startDateUtc, endDateUtc, hideTestUsers, (currentPage - 1) * recordsPerPage, recordsPerPage);
            setFeedbacks(feedbacksResponse?.records ?? []);
            setPagination(s => ({ ...s, totalRecords: feedbacksResponse?.totalRecords ?? 0 }));
            setNpsScore(feedbacksResponse?.npsScore);

        } catch (error) {
            ShowErrorFromResponse(error, "An error ocurred while getting feedbacks.");
        } finally {
            setIsLoading(false);
        }
    }


    const onTodayClick = () => {
        setFromDate(getTodayDate());
        setToDate(getDefaultEndDate());

    }
    const onLast7DaysClick = () => {
        const startDate = addDays(getTodayDate(), -7);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }
    const onLast30DaysClick = () => {
        const startDate = addDays(getTodayDate(), -30);
        setFromDate(startDate);
        setToDate(getDefaultEndDate());

    }

    const onLastMonthClick = () => {
        const dateNow = new Date();
        const firstInMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        let firstInNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        firstInNextMonth.setMonth(firstInMonth.getMonth() + 1);
        const lastDayOfMonth = addDays(firstInNextMonth, -1);
        setFromDate(firstInMonth);
        setToDate(lastDayOfMonth);

    }

    const onLastYearClick = () => {
        const dateNow = new Date();
        const januaryFirst = new Date(dateNow.getFullYear(), 0, 1, 0, 0, 0);
        setFromDate(januaryFirst);
        setToDate(getDefaultEndDate());

    }

    const onAddDayClick = (numberOfDays: number) => {

        const newDate = addDays(fromDate ?? new Date(), numberOfDays);
        setFromDate(newDate);
    }
    const onAddMonthClick = (numberOfMonths: number) => {
        let newDate = !!fromDate ? new Date(fromDate) : new Date();
        newDate.setMonth(newDate.getMonth() + numberOfMonths);
        setFromDate(newDate);
    }

    const selectedUserType = userType !== undefined ? userTypeOptions.find(x => +x.value! == +userType) : undefined;
    const selectedNpsCatergory = npsCategory !== undefined ? npsCategoryOptions.find(x => +x.value! == +npsCategory): undefined;

const { totalRecords, currentPage, recordsPerPage } = pagination;
const totalPages = Math.ceil(totalRecords / recordsPerPage);

return <div> <SubHeader key="sub-header" title="Feedbacks" leftItems={[]} rightItems={[]} />
    <ContentWrapper key="content-wrapper" className="pl-4 pr-4">
        <Card className='mb-4' style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
            <div className="pt-3 pl-3 pr-3 mr-3 d-flex">
                <form onSubmit={(ev) => { ev.preventDefault(); if (currentPage !== 1) { setPagination(s => ({ ...s, currentPage: 1 })); } else { getFeedbacks(); } }} action="">
                    <div className="form-row align-items-center">
                        <div className="col-2 input-group mr-2 mb-2" style={{ minWidth: "350px" }}>
                            <span className="input-group-text"><i className="far fa-user"></i></span>
                            <input type="text"
                                name="searchTerm"
                                className="form-control"
                                onChange={(ev) => { const value = ev.target.value.toString(); setSearchTerm(value); }}
                                placeholder="Display name or User principal name or Email" value={searchTerm}
                            />
                        </div>
                        <div className="col-auto mr-2 mb-2" style={{ minWidth: "160px" }}>
                            <Select
                                value={selectedUserType}
                                placeholder={"User type"}
                                isClearable
                                options={userTypeOptions}
                                onChange={(newValue) => {
                                    const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? +newValue.value : undefined;
                                    setUserType(value);
                                }}
                            />
                        </div>
                        <div className="col-auto mr-2 mb-2" style={{ minWidth: "150px" }}>
                            <Select
                                value={selectedNpsCatergory}
                                placeholder={"NPS category"}
                                isClearable
                                options={npsCategoryOptions}
                                onChange={(newValue) => {
                                    const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? +newValue.value : undefined;
                                    setNpsCategory(value);
                                }}
                            />
                        </div>
                        <div className="input-group mr-2 mb-2" style={{ width: '380px' }}>
                            <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
                            <div className="input-group__datepicker-wrapper">
                                <DatePicker
                                    id="fromDate"
                                    className="form-control"
                                    selected={fromDate}
                                    onChange={(date: Date) => {
                                        if (!date) {
                                            setFromDate(getDefaultStartDate());
                                        } else
                                            setFromDate(new Date(Date.parse(date != null ? date.toString() : "")));
                                    }}
                                    showTimeSelect
                                    maxDate={toDate}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    timeFormat="HH:mm" />
                            </div>
                            <span className="input-group-text">-</span>

                            <div className="input-group__datepicker-wrapper">
                                <DatePicker
                                    id="toDate"
                                    className="form-control"
                                    selected={toDate}
                                    onChange={(date: Date) => {
                                        if (!date) {
                                            setToDate(getDefaultEndDate());
                                        } else
                                            setToDate(new Date(Date.parse(date != null ? date.toString() : "")))
                                    }}
                                    showTimeSelect
                                    maxDate={new Date()}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    timeFormat="HH:mm" />
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" className="datePickerWithDropdown" id="dropdown-basic" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={onTodayClick} >Today</Dropdown.Item>
                                    <Dropdown.Item onClick={onLast7DaysClick}>Last 7 days</Dropdown.Item>
                                    <Dropdown.Item onClick={onLast30DaysClick}>Last 30 days</Dropdown.Item>
                                    <Dropdown.Item onClick={onLastMonthClick}>This month</Dropdown.Item>
                                    <Dropdown.Item onClick={onLastYearClick}>This year</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { onAddDayClick(1) }}>+ day</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { onAddDayClick(-1) }}>- day</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { onAddMonthClick(1) }}>+ month</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { onAddMonthClick(-1) }}>- month</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>


                        </div>

                        <div className="col-auto mr-2">
                            <Form.Check type="checkbox"
                                checked={hideTestUsers}
                                onChange={(ev: any) => { setHideTestUsers(ev.target.checked); }}
                                label="Hide test users" />
                        </div>


                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary mb-2" >Search</button></div>
                    </div>
                </form>


                <div className="mr-3" style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div className={`d-flex flex-column justify-content-center align-items-center mr-4 ${getTextBasedOnOverallScore(npsScore, 'text-danger', 'text-warning', 'text-success', 'text-primary')}`}>
                        <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{npsScore ?? "-"}</b>
                        <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>{getTextBasedOnOverallScore(npsScore, 'Needs improvement', 'Good', 'Great', 'Excellent')}</span>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center mr-2">
                        <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{totalRecords}</b>
                        <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Total records</span>
                    </div>

                </div>
                <div>
                    <select className="form-control form-select" value={recordsPerPage} onChange={(event) => setPagination(s => ({ ...s, recordsPerPage: +event.target.value }))}>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="10000">1000</option>
                    </select>
                </div>

            </div>
        </Card>
        <Card >
            <Card.Body>

                <div className="">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="">User</th>
                                <th className=" text-center" style={{ width: "100px" }}>Score</th>
                                <th className=" text-center" style={{ width: "500px" }}>Comment</th>
                                <th className=" text-center">Created</th>
                            </tr>
                        </thead>
                        <tbody>

                            {feedbacks.map((item) =>
                            (<tr key={item.id} onClick={() => setSelectedFeedbackId(item.id)}>
                                <td className="">
                                    <UserSlimDisplay user={item.user} showDetailsOnClick />
                                </td>
                                <td className="align-middle text-center" style={{ width: "100px" }}>
                                    <span className={`d-flex flex-column justify-content-center align-items-center ${getTextBasedOnFeedbackScore(item.score, 'text-danger', 'text-warning', 'text-success')}`}>
                                        <span style={{ fontSize: '1.5em', lineHeight: 1, fontWeight: 'bold' }}>{item.score}</span>
                                        <small style={{ lineHeight: 1 }}>{getTextBasedOnFeedbackScore(item.score, 'Detractor', 'Passive', 'Promoter')}</small>
                                    </span>
                                </td>
                                <td className={`align-middle text-center ${selectedFeedbackId !== item.id ? "text-one-line-ellipsis" : ""}`}
                                    style={{ maxWidth: "500px" }}>
                                    {item.comment}
                                </td>
                                <td className="align-middle text-center">
                                    <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                        <span><DateTimeDisplayUTC date={item.createdAtUtc!} /></span>
                                    </OverlayTrigger></td>

                            </tr>))
                            }

                            {!!isLoading &&
                                <tr>
                                    <td colSpan={4} className="text-center">Loading...</td>
                                </tr>
                            }


                        </tbody>
                    </table>

                    {totalPages > 1 &&
                        <div className="d-flex align-items-center">
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me page-item'}
                                breakLinkClassName={'page-link'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                pageCount={totalPages}
                                marginPagesDisplayed={4}
                                pageRangeDisplayed={10}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                forcePage={currentPage - 1}
                                onPageChange={(page: any) => {
                                    console.log("Selected page:", page);
                                    setPagination(s => ({ ...s, currentPage: page.selected + 1 }));
                                    document.body.scrollTop = 0; // For Safari
                                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                }}
                                containerClassName={'pagination mb-0'}
                                activeClassName={'active'}
                            />

                        </div>
                    }
                </div>



            </Card.Body>
        </Card>
    </ContentWrapper></div>;
}