import * as React from "react";
import { Card, Dropdown, Form, OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";
import ContentWrapper from "../../layout/components/content-wrapper/content-wrapper.component";
import SubHeader from "../../layout/components/sub-header/sub-header.component";
import { LoginApplications,  OrganizationType,  UserActivityLevel, UserResultModel, UsersClient, UserTagResponseModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import UserDetailsModal from "./user-details-modal/user-details-modal.component";
import LoadingService from "../../components/loading-indicator/loading-indicator.service";
import { EmailWithLinkToDomain } from "../../components/email-with-link-to-domain/email-with-link-to-domain.component";
import ReactPaginate from "react-paginate";
import { UtcTimePopover } from "../screenshots/screenshots.component";
import ConsumerDomainsModal from "./consumer-domains-modal.component";
import { TargetProcessLink } from "../../components/target-process-link/target-process-link.component";
import CreateMotherTicketModal from "./create-mother-ticket-modal.component";
import { CreateMotherTicketButton } from "./create-mother-ticket-button.component";
import "./users-table-with-caching.styless.scss";
import { getUserLoginsClient } from "../../services/api-clients.service";
import { UserLastLogin } from "./user-last-login.component";
import { LoginApplicationSelect } from "./login-application-select/login-application-select.component";
import Select from "react-select";
import { ReactSelectOption } from "./login-application-select/login-application-select.component";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import { TestUsersModal } from "./test-users-modal.component";
import DatePicker from "react-datepicker";
import { addDays } from "../../utilities/date.utilities";
import { UserActivityLevelBadge } from "./user-activity-level-badge.component";
import { UserActivityLevelSelect } from "./user-activity-level-select/user-activity-level-select.component";
import UserTagsModal from "./user-tags-modal.component";
import UserTagPicker, { TagOption, UserTagPickerType } from "../../components/user-tag-picker/user-tag-picker.component";
import { TagResponseModel } from "../../swagger-clients/dashboard-clients.service";
import { UserTagFilter, UserTagFilterType } from "../../components/user-tag-filter/user-tag-filter.component";
import { ZoomParticipantsSyncModal } from "./zoom-participants-sync-modal.component";
import queryString from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BootstrapTooltip from "../../components/bootstrap-tooltip/bootstrap-tooltip";

type IUserTableWithCachingProps = RouteComponentProps & {

}

type IUsersQueryParameters = {
    searchTerm: string;
    motherTicketId?: number;
    createdFromDate?: Date;
    createdToDate?: Date;
    hideConsumers: boolean;
    hideTestUsers: boolean;
    hideUsersWithMotherTicket: boolean;
    mtUserType?: OrganizationType;
    tagFilterId?: number;
    orderby?: string;
    orderByDescending: boolean;
    loggedInApplications: LoginApplications[];
    showByActivity: UserActivityLevel[];
    isVisitedApplicationSelected: boolean;
    currentPage: number;
    recordsPerPage: number;
}

type IUserTableState = {
    filters: IUsersQueryParameters,
    totalRecords: number;
    users: UserResultModel[];
    isDataLoaded: boolean;
    showDetailsModal: boolean;
    selectedUser?: UserResultModel;
    showConsumerDomainsModal: boolean;
    showUserTagsModal: boolean;
    showTestUsersModal: boolean;
    showCreateMotherTicketModal: boolean;
    showZoomUmParticipantsSync: boolean;
}



const getTodayDate = () => {
    let dateNow = new Date();
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0);
    return dateNow;
}

const getDefaultStartDate = () => {

    return addDays(getTodayDate(), -30);
}


const getDefaultEndDate = () => {
    let tomorrow = addDays(new Date(), 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    return tomorrow;

}

const organizationTypeOptions: ReactSelectOption[] = [
    {
        label: "Company", value: OrganizationType.Company
    },
    {
        label: "University", value: OrganizationType.University
    },
    {
        label: "Consulting", value: OrganizationType.Consulting
    }
];

const getDefaultFilter = () => ({
    searchTerm: "",
    motherTicketId: undefined,
    createdFromDate: undefined,
    createdToDate: undefined,
    hideConsumers: false,
    hideTestUsers: true,
    hideUsersWithMotherTicket: false,
    mtUserType: undefined,
    tagFilterId: undefined,
    orderby: "createdDateTime",
    orderByDescending: true,
    loggedInApplications: [],
    showByActivity: [],
    isVisitedApplicationSelected: true,
    currentPage: 1,
    recordsPerPage: 50,
} as IUsersQueryParameters);

class UserTableWithCaching extends React.Component<IUserTableWithCachingProps, IUserTableState> {

    tagPickerRef: React.MutableRefObject<UserTagPickerType | null>;
    tagFilterRef: React.MutableRefObject<UserTagFilterType | null>;

    constructor(props: IUserTableWithCachingProps) {
        super(props);
        this.tagPickerRef = React.createRef<UserTagPickerType>();
        this.tagFilterRef = React.createRef<UserTagFilterType>();

        const filters: IUsersQueryParameters = this.getFiltersFromQueryParams(getDefaultFilter());

        this.state = {
            filters: filters,
            totalRecords: 0,
            users: [],
            isDataLoaded: false,
            showDetailsModal: false,
            showConsumerDomainsModal: false,
            showUserTagsModal: false,
            showTestUsersModal: false,
            showCreateMotherTicketModal: false,
            showZoomUmParticipantsSync: false
        } as IUserTableState;
    }

    getFiltersFromQueryParams(defaultValues: IUsersQueryParameters) {
        const params: IUsersQueryParameters = queryString.parse(this.props.location.search, {
            parseNumbers: true,
            parseBooleans: true,
        }) as any;

        const filters = { ...defaultValues };

        for (let k of Object.keys(params)) {
            (filters as any)[k] = (params as any)[k];
        }

        return filters;
    }


    async componentDidMount() {

        await this.getUsers();
    }

    async componentDidUpdate(prevProps: Readonly<IUserTableWithCachingProps>, prevState: Readonly<IUserTableState>, snapshot?: any) {
        if (prevState.filters.recordsPerPage !== this.state.filters.recordsPerPage ||
            prevState.filters.currentPage !== this.state.filters.currentPage) {
            await this.getUsers();
        }
    }

    updateUrl = () => {
        const { history } = this.props;
        const queryParams = this.getQueryString(this.state.filters, getDefaultFilter());
        history.replace(`?${queryParams}`);
    };

    getQueryString = (filters: IUsersQueryParameters, defaultValues: IUsersQueryParameters) => {
        let clone: IUsersQueryParameters = { ...filters };

        for (let k of Object.keys(clone)) {
            if ((clone as any)[k] == (defaultValues as any)[k])
                delete (clone as any)[k];
        }

        // Hack 
        if (!!clone.createdToDate)
            clone.createdToDate = clone.createdToDate.toISOString() as any;
        if (!!clone.createdFromDate)
            clone.createdFromDate = clone.createdFromDate.toISOString() as any;

        const queryParams = queryString.stringify(clone as any, {
            skipNull: true,
            skipEmptyString: true
        });

        return queryParams;
    }

    async getUsers() {
        try {
            this.updateUrl();
            LoadingService.showLoading();
            this.setState({ isDataLoaded: false });
            const { searchTerm, orderByDescending, orderby, createdFromDate, createdToDate, showByActivity, mtUserType, tagFilterId,
                recordsPerPage, currentPage, hideConsumers, hideTestUsers, hideUsersWithMotherTicket, motherTicketId, loggedInApplications, isVisitedApplicationSelected } = this.state.filters;
            const client = new UsersClient(process.env.REACT_APP_BASE_URL);
            const loggedInApps = isVisitedApplicationSelected ? loggedInApplications : [];
            const notLoggedInApps = !isVisitedApplicationSelected ? loggedInApplications : [];

            const createdFromDateUtc = createdFromDate ? new Date(Date.UTC(createdFromDate.getFullYear(), createdFromDate.getMonth(), createdFromDate.getDate(), createdFromDate.getHours(), createdFromDate.getMinutes(), createdFromDate.getSeconds())) : null;
            const createdToDateUtc = createdToDate ? new Date(Date.UTC(createdToDate.getFullYear(), createdToDate.getMonth(), createdToDate.getDate(), createdToDate.getHours(), createdToDate.getMinutes(), createdToDate.getSeconds())) : undefined;


            const usersResp = await client.getCachedUsers((currentPage - 1) * recordsPerPage, recordsPerPage,
                hideConsumers, hideTestUsers, mtUserType, tagFilterId, hideUsersWithMotherTicket, createdFromDateUtc, createdToDateUtc, searchTerm, motherTicketId,
                loggedInApps, notLoggedInApps, showByActivity, orderby, orderByDescending);

            this.setState(s => ({ ...s, totalRecords: usersResp.totalRecords, users: [...usersResp.items] }), () => { console.log("State after", this.state) });

        } catch (error) {
            console.log("An error occurred while loading users.", error);
            toast.error("An error occurred while loading users.");
        } finally {
            this.setState({ isDataLoaded: true });
            LoadingService.hideLoading();
        }
    }



    async onClearCacheClick() {
        try {
            const client = new UsersClient(process.env.REACT_APP_BASE_URL);
            await client.clearUsersCache();
            toast.success("Cache successfully cleared. Please reload users after few seconds.");
        } catch (error) {
            console.log("An error occurred while clearing users cache.", error);
            toast.error("An error occurred while clearing users cache.");
        }

    }

    updateFilters(func: (f: IUsersQueryParameters) => void) {
        let clone = { ...this.state.filters };
        func(clone);
        this.setState(s => ({ ...s, filters: { ...clone } }));
    }

    async onColumnClick(column: string) {
        const { orderby, orderByDescending } = this.state.filters;
        if (orderby == column) {
            this.setState(s => ({ filters: { ...s.filters, orderByDescending: !s.filters.orderByDescending } }), async () => { await this.getUsers(); });
        } else {
            this.setState(s => ({ filters: { ...s.filters, orderby: column, orderByDescending: true } }), async () => { await this.getUsers(); });
        }
    }

    getSortValue = (columnName: string) => {
        const { orderByDescending, orderby } = this.state.filters;
        if (orderby == columnName) {
            if (orderByDescending) {
                return "desc";
            } else {
                return "asc";
            }
        }
        return undefined;
    }

    SetCurrentPage = (page: number) => {
        console.log("SetCurrentPage", page);
        //this.setState(s => ({ ...s, currentPage: page }));
        this.updateFilters(f => f.currentPage = page);
    }

    onMotherTicketCreated = (motherTicketId: number, userId: string) => {

        const { users } = this.state;
        const updatedUsers = users.map((user) => {
            if (user.id == userId) {
                return { ...user, targetProcesMotherTicketIds: [...(user.targetProcesMotherTicketIds ?? []), motherTicketId] } as UserResultModel;
            }
            return user;
        });
        this.setState({ users: [...updatedUsers], showCreateMotherTicketModal: false });
    }

    setToDate(date?: Date) {
        const toDate = date != null && !!date ? new Date(Date.parse(date.toString())) : undefined;
        this.updateFilters(f => f.createdToDate = toDate);
    }
    setFromDate(date?: Date) {
        const fromDate = date != null && !!date ? new Date(Date.parse(date.toString())) : undefined;
        this.updateFilters(f => f.createdFromDate = fromDate);
    }

    onTodayClick = () => {
        this.setFromDate(getTodayDate());
        this.setToDate(getDefaultEndDate());

    }
    onLast7DaysClick = () => {
        const startDate = addDays(getTodayDate(), -7);
        this.setFromDate(startDate);
        this.setToDate(getDefaultEndDate());

    }
    onLast30DaysClick = () => {
        const startDate = addDays(getTodayDate(), -30);
        this.setFromDate(startDate);
        this.setToDate(getDefaultEndDate());

    }

    onLastMonthClick = () => {
        const dateNow = new Date();
        const firstInMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        let firstInNextMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0);
        firstInNextMonth.setMonth(firstInMonth.getMonth() + 1);
        const lastDayOfMonth = addDays(firstInNextMonth, -1);
        this.setFromDate(firstInMonth);
        this.setToDate(lastDayOfMonth);

    }

    onLastYearClick = () => {
        const dateNow = new Date();
        const januaryFirst = new Date(dateNow.getFullYear(), 0, 1, 0, 0, 0);
        this.setFromDate(januaryFirst);
        this.setToDate(getDefaultEndDate());

    }

    onAddDayClick = (numberOfDays: number) => {
        const { createdFromDate } = this.state.filters;
        const newDate = addDays(createdFromDate ?? new Date(), numberOfDays);
        this.setFromDate(newDate);
    }
    onAddMonthClick = (numberOfMonths: number) => {
        const { createdFromDate } = this.state.filters;
        let newDate = createdFromDate ?? new Date();
        newDate.setMonth(newDate.getMonth() + numberOfMonths);
        this.setFromDate(newDate);
    }

    onUserTagsChanged = (userId: string, tags: TagOption[]) => {
        const updatedUsers = this.state.users.map(x => {
            if (x.id == userId) {
                const mappedTags = tags?.map(tag => new UserTagResponseModel({ id: +tag.value, name: tag.label, createdAtUtc: new Date() })) ?? [];
                return new UserResultModel({ ...x, tags: mappedTags });
            }
            return x;
        });
        this.setState(s => ({ ...s, users: updatedUsers }));

    }


    render() {

        const { searchTerm, motherTicketId, hideConsumers, hideTestUsers, loggedInApplications,
            showByActivity, mtUserType, tagFilterId,
            hideUsersWithMotherTicket, recordsPerPage, currentPage,
            isVisitedApplicationSelected, createdFromDate, createdToDate } = this.state.filters;

        const { users, isDataLoaded, showDetailsModal, selectedUser, showConsumerDomainsModal, showUserTagsModal, showTestUsersModal, totalRecords,
            showCreateMotherTicketModal, showZoomUmParticipantsSync } = this.state;

        const totalPages = Math.ceil(totalRecords / recordsPerPage);
        //  console.log("totalPages,totalRecords,recordsPerPage", totalPages, totalRecords, recordsPerPage);

        const leftHeaderItems = [
            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.onClearCacheClick() }} >Refresh cache</button>,
            <button type="button" className="btn btn-primary btn-sm ml-2"
                onClick={() => { this.setState(s => ({ ...s, showConsumerDomainsModal: true })) }} >
                Consumer domains
            </button>,
            <button type="button" className="btn btn-primary btn-sm ml-2"
                onClick={() => { this.setState(s => ({ ...s, showTestUsersModal: true })) }} >
                Test users
            </button>,
            <button type="button" className="btn btn-primary btn-sm ml-2"
                onClick={() => { this.setState(s => ({ ...s, showUserTagsModal: true })) }} >
                Tags
            </button>,
            <button type="button" className="btn btn-primary btn-sm ml-2"
                onClick={() => { this.setState(s => ({ ...s, showZoomUmParticipantsSync: true })) }} >
                Zoom Sync
            </button>,
        ];


        const vistedValue = !!isVisitedApplicationSelected ? visitOptions[0] : visitOptions[1];
        const selectedMTUserType = mtUserType !== undefined ? organizationTypeOptions.find(x => +x.value! == +mtUserType) : undefined;

        return [<SubHeader key="sub-header" title="Users" leftItems={leftHeaderItems} rightItems={[]} />,
        <ContentWrapper key="content-wrapper" className="pl-4 pr-4">
            <Card className='mb-4' style={{ marginRight: "auto", marginLeft: "auto", width: "100%" }}>
                <div className="pt-3 pl-3 pr-3 mr-3 d-flex">
                    <form onSubmit={(ev) => { ev.preventDefault(); if (currentPage !== 1) { this.SetCurrentPage(1); } else { this.getUsers(); } }} action="">
                        <div className="form-row align-items-center">
                            <div className="col-auto" style={{ minWidth: "330px" }}>
                                <input type="text"
                                    name="searchTerm"
                                    className="form-control mb-2"
                                    onChange={e => this.updateFilters(f => f.searchTerm = e.target.value)}
                                    placeholder="Display name or User principal name or Email" value={searchTerm} />
                            </div>

                            <div className="col-auto" style={{ width: "150px" }}>
                                <input type="text"
                                    name="motherTicketId"
                                    className="form-control mb-2"
                                    onChange={e => this.updateFilters(f => f.motherTicketId = !!e.target.value ? +e.target.value : undefined)}
                                    placeholder="Mother ticket Id" value={motherTicketId} />
                            </div>
                            <div className="input-group mr-2 mb-3" style={{ width: '380px' }}>
                                <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
                                <div className="input-group__datepicker-wrapper">
                                    <DatePicker
                                        id="fromDate"
                                        className="form-control"
                                        selected={createdFromDate}
                                        onChange={(date: Date) => {
                                            if (!date) { this.setFromDate(undefined); }
                                            else
                                                this.setFromDate(date);
                                        }}
                                        showTimeSelect
                                        maxDate={createdToDate}
                                        placeholderText="Created from"
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        autoComplete="off" />
                                </div>
                                <span className="input-group-text">-</span>

                                <div className="input-group__datepicker-wrapper">
                                    <DatePicker
                                        id="toDate"
                                        className="form-control"
                                        selected={createdToDate}
                                        onChange={(date: Date) => {
                                            if (!date) { this.setToDate(undefined); }
                                            else
                                                this.setToDate(date);
                                        }}
                                        showTimeSelect
                                        maxDate={addDays(new Date(), 1)}
                                        placeholderText="Created to"
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        autoComplete="off" />
                                </div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" className="datePickerWithDropdown" id="dropdown-basic" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.onTodayClick} >Today</Dropdown.Item>
                                        <Dropdown.Item onClick={this.onLast7DaysClick}>Last 7 days</Dropdown.Item>
                                        <Dropdown.Item onClick={this.onLast30DaysClick}>Last 30 days</Dropdown.Item>
                                        <Dropdown.Item onClick={this.onLastMonthClick}>This month</Dropdown.Item>
                                        <Dropdown.Item onClick={this.onLastYearClick}>This year</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { this.onAddDayClick(1) }}>+ day</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { this.onAddDayClick(-1) }}>- day</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { this.onAddMonthClick(1) }}>+ month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { this.onAddMonthClick(-1) }}>- month</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>

                            <div className="col-auto" style={{ width: "400px", display: "flex" }}>
                                <div style={{ width: "140px" }}>
                                    <Select
                                        className="mb-2"
                                        value={vistedValue}
                                        options={visitOptions}
                                        onChange={data => this.updateFilters(f => f.isVisitedApplicationSelected = data?.value == 1)}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderRight: 0,
                                                borderBottomRightRadius: 0,
                                                borderTopRightRadius: 0
                                            }),
                                            indicatorSeparator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                display: "none"
                                            })
                                        }}

                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <LoginApplicationSelect
                                        values={loggedInApplications}
                                        className="mb-2"
                                        placeholder="Logins"
                                        onChange={data => this.updateFilters(f => f.loggedInApplications = (data as any) ?? [])} />
                                </div>

                            </div>
                            <div className="col-auto mr-2" style={{ minWidth: "250px" }}>
                                <UserActivityLevelSelect
                                    values={showByActivity}
                                    className="mb-2"
                                    placeholder="Show by activity"
                                    onChange={data => this.updateFilters(f => f.showByActivity = (data as any) ?? [])} />
                            </div>
                            <div className="col-auto mr-2" style={{ minWidth: "200px" }}>
                                <Select
                                    value={selectedMTUserType}
                                    className="mb-2"
                                    placeholder={"Organization type"}
                                    isMulti={false}
                                    isClearable
                                    options={organizationTypeOptions}
                                    onChange={(newValue) => {
                                        const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? +newValue.value : undefined;
                                        this.updateFilters(f => f.mtUserType = value);
                                    }}
                                />
                            </div>
                            <div className="col-auto mr-2" style={{ minWidth: "200px" }}>
                                <UserTagFilter
                                    value={tagFilterId}
                                    ref={this.tagFilterRef}
                                    className="mb-2"
                                    placeholder={"Select tag"}

                                    onChange={(newValue) => {
                                        const value = newValue !== undefined && newValue !== null && newValue.value !== null && newValue.value !== undefined ? +newValue.value : undefined;
                                        this.updateFilters(f => f.tagFilterId = value);
                                    }}
                                />
                            </div>

                            <div className="col-auto mr-2">
                                <Form.Check type="checkbox"
                                    checked={hideConsumers}
                                    onChange={(ev: any) => this.updateFilters(f => f.hideConsumers = ev.target.checked)}
                                    label="Hide consumers" />
                            </div>
                            <div className="col-auto mr-2">
                                <Form.Check type="checkbox"
                                    checked={hideTestUsers}
                                    onChange={(ev: any) => this.updateFilters(f => f.hideTestUsers = ev.target.checked)}
                                    label="Hide test users" />
                            </div>
                            <div className="col-auto mr-2">
                                <Form.Check type="checkbox"
                                    checked={hideUsersWithMotherTicket}
                                    onChange={(ev: any) => this.updateFilters(f => f.hideUsersWithMotherTicket = ev.target.checked)}
                                    label="Hide MT Users" />
                            </div>
                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary mb-2" >Search</button></div>
                        </div>
                    </form>

                    <div className="mr-3"
                        style={{
                            marginLeft: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: "flex-start",
                            textAlign: "center",
                            gap: "2px"
                        }}>
                        <b style={{ fontSize: '1.5em', lineHeight: '1em' }}>{totalRecords}</b>
                        <span style={{ fontSize: '0.8em', lineHeight: '0.8em' }}>Total records</span>
                    </div>
                    <div style={{ minWidth: "75px" }}>
                        <select
                            className="form-control form-select"
                            value={recordsPerPage}
                            onChange={(event) => this.setState(s => ({ filters: { ...s.filters, recordsPerPage: +event.target.value } }))}>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                        </select>
                    </div>

                </div>
            </Card>
            <Card >
                <Card.Body>

                    <div className="">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th
                                        className={"sortable " + this.getSortValue("displayName")}
                                        onClick={() => { this.onColumnClick.call(this, "displayName") }}
                                    >Display name</th>

                                    <th className={"sortable " + this.getSortValue("email")}
                                        onClick={() => { this.onColumnClick.call(this, "email") }}>Email</th>

                                    <th className={"text-center sortable " + this.getSortValue("organizationMTUserCount")}
                                        onClick={() => { this.onColumnClick.call(this, "organizationMTUserCount") }}
                                    >Organization</th>

                                    <th style={{ minWidth: '100px' }}>Tags</th>
                                    <th className={"text-center sortable " + this.getSortValue("createdDateTime")}
                                        onClick={() => { this.onColumnClick.call(this, "createdDateTime") }}
                                    >Created at</th>
                                    <th className={"text-center sortable " + this.getSortValue("acceptedEulaDashboard")}
                                        onClick={() => { this.onColumnClick.call(this, "acceptedEulaDashboard") }}>Dashboard<br />EULA</th>
                                    <th className={"text-center sortable " + this.getSortValue("acceptedEulaDwsimPro")}
                                        onClick={() => { this.onColumnClick.call(this, "acceptedEulaDwsimPro") }}>DWSIM Pro <br />EULA</th>
                                    <th className={"text-center sortable " + this.getSortValue("confirmedRegistrationAt")}
                                        onClick={() => { this.onColumnClick.call(this, "confirmedRegistrationAt") }}>Confirmed<br /> registration</th>
                                    <th className={"text-center sortable " + this.getSortValue("tourWatched")}
                                        onClick={() => { this.onColumnClick.call(this, "tourWatched") }}>Watched<br /> Tour</th>
                                    <th className="text-center">Logins</th>

                                </tr>
                            </thead>
                            <tbody>

                                {users.map((user) =>
                                (<tr className="users-table-row" key={`${user.id}-${user.userPrincipalName}`}
                                    onClick={() => { this.setState({ showDetailsModal: true, selectedUser: user }) }}>
                                    <td><UserActivityLevelBadge userActivityLevel={user.activityLevel} /></td>
                                    <td>
                                        <span className="align-middle d-flex flex-column" style={{ lineHeight: "13px" }}>
                                            {user.displayName}
                                            <small>{user.userPrincipalName} </small>
                                        </span>

                                    </td>
                                    <td className="align-middle"><EmailWithLinkToDomain email={user.email} />

                                        {user.isUniversity && <BootstrapTooltip tooltipText="University"><span style={{ marginLeft: "3px" }}><i className="fas fa-graduation-cap" style={{ color: "black" }}></i></span></BootstrapTooltip>}
                                        {user.isConsulting && <BootstrapTooltip tooltipText="Consulting"><span style={{ marginLeft: "3px" }}><i className="bi bi-briefcase" style={{ color: "black" }}></i></span></BootstrapTooltip>}
                                    </td>
                                    <td className="align-middle text-center">
                                        {!!user.targetProcesMotherTicketIds && user.targetProcesMotherTicketIds.map((ticketId) => {
                                            return <TargetProcessLink className="flex-column" targetProcesMotherTicketId={ticketId} />
                                        })
                                        }
                                        {(!user.targetProcesMotherTicketIds || user.targetProcesMotherTicketIds.length == 0) &&
                                            <CreateMotherTicketButton className="mother-ticket-button" onClick={() => { this.setState({ selectedUser: user, showCreateMotherTicketModal: true }) }} />}
                                        {user.targetProcesMotherTicketIds && user.targetProcesMotherTicketIds.length > 0 &&
                                            <span style={{ marginLeft: "3px" }}>({user.organizationMTUserCount ?? "-"})</span>}
                                    </td>
                                    <td onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); }}>
                                        <UserTagPicker ref={this.tagPickerRef} userId={user.id!} tags={user.tags ?? []}
                                            onTagsChanged={(tags) => { this.onUserTagsChanged(user.id!, tags) }}
                                            onTagCreated={() => {
                                                console.log("onUserTagsChanged", this.tagFilterRef?.current, this.tagPickerRef?.current);
                                                this.tagFilterRef?.current?.GetOptions();
                                                this.tagPickerRef?.current?.getSuggestions();
                                            }}
                                        />

                                    </td>
                                    <td className="align-middle text-center">
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={UtcTimePopover}>
                                            <span><DateTimeDisplayUTC date={user.createdAt} /></span>
                                        </OverlayTrigger>
                                    </td>
                                    <td className="align-middle text-center">{!!user.acceptedEulaDashboard ? "YES" : "NO"}</td>
                                    <td className="align-middle text-center">{!!user.acceptedEulaDwsimPro ? "YES" : "NO"}</td>
                                    <td className="align-middle text-center">{!!user.confirmedRegistrationAt ? "YES" : "NO"}</td>
                                    <td className="align-middle text-center">{!!user.tourWatched ? "YES" : "NO"}</td>
                                    <td className="align-middle text-center"><UserLastLogin user={user} /></td>
                                </tr>))
                                }

                                {!isDataLoaded &&
                                    <tr>
                                        <td colSpan={10} className="text-center">Loading...</td>
                                    </tr>
                                }


                            </tbody>
                        </table>

                        {totalPages > 1 &&
                            <div className="d-flex align-items-center">
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me page-item'}
                                    breakLinkClassName={'page-link'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    pageCount={totalPages}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={10}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    forcePage={currentPage - 1}
                                    onPageChange={(page: any) => {
                                        console.log("Selected page:", page);
                                        this.SetCurrentPage(page.selected + 1);
                                        document.body.scrollTop = 0; // For Safari
                                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                    }}
                                    containerClassName={'pagination mb-0'}
                                    activeClassName={'active'}
                                />

                            </div>
                        }
                    </div>

                    {showDetailsModal && <UserDetailsModal
                        show={true}
                        userId={selectedUser?.id ?? ""}
                        userDisplayName={selectedUser?.displayName ?? ""}
                        userPrincipalName={selectedUser?.userPrincipalName ?? ""}
                        onMotherTicketChanged={this.onMotherTicketCreated}
                        onReloadUsers={() => { this.setState({ showDetailsModal: false, selectedUser: undefined }, () => { this.getUsers(); }); }}
                        onHide={() => this.setState({ showDetailsModal: false, selectedUser: undefined })} />
                    }

                    {showConsumerDomainsModal &&
                        <ConsumerDomainsModal show={true} onHide={() => this.setState(s => ({ ...s, showConsumerDomainsModal: false }))} />}

                    <UserTagsModal show={showUserTagsModal}
                        onHide={() => this.setState(s => ({ ...s, showUserTagsModal: false }))}
                        onTagsChanged={() => {

                            this.tagFilterRef?.current?.GetOptions(); this.tagPickerRef?.current?.getSuggestions();
                        }}
                    />

                    {showTestUsersModal &&
                        <TestUsersModal show={true} onHide={() => this.setState(s => ({ ...s, showTestUsersModal: false }))} />}

                    {showCreateMotherTicketModal && selectedUser &&
                        <CreateMotherTicketModal
                            show={true}
                            user={selectedUser}
                            onSuccess={(motherTicketId) => this.onMotherTicketCreated(motherTicketId, selectedUser.id!)}
                            onHide={() => { this.setState(s => ({ ...s, showCreateMotherTicketModal: false })) }}

                        />}

                    {showZoomUmParticipantsSync && <ZoomParticipantsSyncModal show onHide={() => { this.setState(s => ({ ...s, showZoomUmParticipantsSync: false })) }} />}

                </Card.Body>
            </Card>
        </ContentWrapper>];
    }
}

const visitOptions: ReactSelectOption[] = [
    { value: 1, label: "Logged In" },
    { value: 2, label: "Not Logged In" }
];

export default withRouter(UserTableWithCaching);